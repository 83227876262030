import { crumbsConfig } from '../navigation/crumbs';
import { pathCrumb } from '../navigation/crumbs/types';
import { useEffect, useMemo, useState } from 'react';
import { matchPath, matchRoutes, useLocation, useResolvedPath } from 'react-router';

export const useGetCrumbs = () => {
  const location = useLocation();
  const path = location.pathname;

  const [main, setMain] = useState<pathCrumb[]>([]);
  const [item, setItem] = useState<null | string>(null);
  const [hideMainCard, setHideMainCard] = useState<boolean | undefined>();
  useEffect(() => {
    const currentRoute = crumbsConfig.find((route) => route.path && matchPath(route.path, path));
    if (!currentRoute) return;
    setMain(currentRoute.pathCrumbs);
    setItem(currentRoute.name);
    setHideMainCard(currentRoute.hideMainCard);
  }, [path]);
  return { main, item, hideMainCard };
};
