import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractService from '../../services/contract.service';

const initialState = [];

export const retrieveContracts = createAsyncThunk('contracts/retrieve', async () => {
  const res = await ContractService.getAll();
  return res.data;
});

export const retrieveContract = createAsyncThunk('contract/retrieve', async (id) => {
  const res = await ContractService.get(id);
  return res.data;
});

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  extraReducers: {
    [retrieveContracts.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = contractSlice;
export default reducer;
