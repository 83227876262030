var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// material-ui
import { alpha } from '@mui/material/styles';
function getColorStyle(_a) {
    var variant = _a.variant, color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var lighter = colors.lighter, main = colors.main, dark = colors.dark, contrastText = colors.contrastText;
    var shadows = theme.customShadows["".concat(color, "Button")];
    var commonShadow = {
        '&::after': {
            boxShadow: "0 0 5px 5px ".concat(alpha(main, 0.9))
        },
        '&:active::after': {
            boxShadow: "0 0 0 0 ".concat(alpha(main, 0.9))
        },
        '&:focus-visible': {
            outline: "2px solid ".concat(dark),
            outlineOffset: 2
        }
    };
    switch (variant) {
        case 'contained':
            return __assign({ '&:hover': {
                    backgroundColor: dark
                } }, commonShadow);
        case 'shadow':
            return __assign({ color: contrastText, backgroundColor: main, boxShadow: shadows, '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: dark
                } }, commonShadow);
        case 'outlined':
            return __assign({ borderColor: main, '&:hover': {
                    color: dark,
                    backgroundColor: 'transparent',
                    borderColor: dark
                } }, commonShadow);
        case 'dashed':
            return __assign({ color: main, borderColor: main, backgroundColor: lighter, '&:hover': {
                    color: dark,
                    borderColor: dark
                } }, commonShadow);
        case 'text':
        default:
            return __assign({ '&:hover': {
                    color: dark,
                    backgroundColor: lighter
                } }, commonShadow);
    }
}
// ==============================|| OVERRIDES - BUTTON ||============================== //
export default function Button(theme) {
    var primaryDashed = getColorStyle({ variant: 'dashed', color: 'primary', theme: theme });
    var primaryShadow = getColorStyle({ variant: 'shadow', color: 'primary', theme: theme });
    var disabledStyle = {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[200]
        }
    };
    var iconStyle = {
        '&>*:nth-of-type(1)': {
            fontSize: 'inherit'
        }
    };
    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    '&::after': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 4,
                        opacity: 0,
                        transition: 'all 0.5s'
                    },
                    '&:active::after': {
                        position: 'absolute',
                        borderRadius: 4,
                        left: 0,
                        top: 0,
                        opacity: 1,
                        transition: '0s'
                    }
                },
                contained: __assign({}, disabledStyle),
                outlined: __assign({}, disabledStyle),
                text: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
                endIcon: __assign({}, iconStyle),
                startIcon: __assign({}, iconStyle),
                dashed: __assign(__assign({ border: '1px dashed' }, primaryDashed), { '&.MuiButton-dashedPrimary': getColorStyle({ variant: 'dashed', color: 'primary', theme: theme }), '&.MuiButton-dashedSecondary': getColorStyle({ variant: 'dashed', color: 'secondary', theme: theme }), '&.MuiButton-dashedError': getColorStyle({ variant: 'dashed', color: 'error', theme: theme }), '&.MuiButton-dashedSuccess': getColorStyle({ variant: 'dashed', color: 'success', theme: theme }), '&.MuiButton-dashedInfo': getColorStyle({ variant: 'dashed', color: 'info', theme: theme }), '&.MuiButton-dashedWarning': getColorStyle({ variant: 'dashed', color: 'warning', theme: theme }), '&.Mui-disabled': {
                        color: "".concat(theme.palette.grey[300], " !important"),
                        borderColor: "".concat(theme.palette.grey[400], " !important"),
                        backgroundColor: "".concat(theme.palette.grey[200], " !important")
                    } }),
                shadow: __assign(__assign({}, primaryShadow), { '&.MuiButton-shadowPrimary': getColorStyle({ variant: 'shadow', color: 'primary', theme: theme }), '&.MuiButton-shadowSecondary': getColorStyle({ variant: 'shadow', color: 'secondary', theme: theme }), '&.MuiButton-shadowError': getColorStyle({ variant: 'shadow', color: 'error', theme: theme }), '&.MuiButton-shadowSuccess': getColorStyle({ variant: 'shadow', color: 'success', theme: theme }), '&.MuiButton-shadowInfo': getColorStyle({ variant: 'shadow', color: 'info', theme: theme }), '&.MuiButton-shadowWarning': getColorStyle({ variant: 'shadow', color: 'warning', theme: theme }), '&.Mui-disabled': {
                        color: "".concat(theme.palette.grey[300], " !important"),
                        borderColor: "".concat(theme.palette.grey[400], " !important"),
                        backgroundColor: "".concat(theme.palette.grey[200], " !important")
                    } }),
                containedPrimary: getColorStyle({ variant: 'contained', color: 'primary', theme: theme }),
                containedSecondary: getColorStyle({ variant: 'contained', color: 'secondary', theme: theme }),
                containedError: getColorStyle({ variant: 'contained', color: 'error', theme: theme }),
                containedSuccess: getColorStyle({ variant: 'contained', color: 'success', theme: theme }),
                containedInfo: getColorStyle({ variant: 'contained', color: 'info', theme: theme }),
                containedWarning: getColorStyle({ variant: 'contained', color: 'warning', theme: theme }),
                outlinedPrimary: getColorStyle({ variant: 'outlined', color: 'primary', theme: theme }),
                outlinedSecondary: getColorStyle({ variant: 'outlined', color: 'secondary', theme: theme }),
                outlinedError: getColorStyle({ variant: 'outlined', color: 'error', theme: theme }),
                outlinedSuccess: getColorStyle({ variant: 'outlined', color: 'success', theme: theme }),
                outlinedInfo: getColorStyle({ variant: 'outlined', color: 'info', theme: theme }),
                outlinedWarning: getColorStyle({ variant: 'outlined', color: 'warning', theme: theme }),
                textPrimary: getColorStyle({ variant: 'text', color: 'primary', theme: theme }),
                textSecondary: getColorStyle({ variant: 'text', color: 'secondary', theme: theme }),
                textError: getColorStyle({ variant: 'text', color: 'error', theme: theme }),
                textSuccess: getColorStyle({ variant: 'text', color: 'success', theme: theme }),
                textInfo: getColorStyle({ variant: 'text', color: 'info', theme: theme }),
                textWarning: getColorStyle({ variant: 'text', color: 'warning', theme: theme }),
                sizeExtraSmall: {
                    minWidth: 56,
                    fontSize: '0.625rem',
                    padding: '2px 8px'
                }
            }
        }
    };
}
