import { createContext, useCallback, useState, useMemo, useEffect, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { useNotifications } from '../NotificationsContext';
import type { BaseJSONRepresentation, JobStatus } from './JobBase';
import { type ShipmentDocumentStatusPollJobJSON, ShipmentDocumentStatusPollJob } from './ShipmentDocumentStatusPollJob';
import {
  ShippingInstructionsDocumentStatusPollJob,
  ShippingInstructionsDocumentStatusPollJobJSON
} from './ShippingInstructionsDocumentStatusPollJob';
import { type SplitShipmentStatusPollJobJSON, SplitShipmentStatusPollJob } from './SplitShipmentStatusPollJob';
import { ContractDocumentStatusPollJob, ContractDocumentStatusPollJobJSON } from './ContractDocumentStatusPollJob';

type JobInstance =
  | ShipmentDocumentStatusPollJob
  | ShippingInstructionsDocumentStatusPollJob
  | SplitShipmentStatusPollJob
  | ContractDocumentStatusPollJob;
type JobJSON =
  | ShipmentDocumentStatusPollJobJSON
  | ShippingInstructionsDocumentStatusPollJobJSON
  | SplitShipmentStatusPollJobJSON
  | ContractDocumentStatusPollJobJSON;

const jobFromJSON = (saveJobs: () => void, json: JobJSON): JobInstance | null => {
  switch (json.kind) {
    case 'ShipmentDocumentStatusPollJob': {
      return new ShipmentDocumentStatusPollJob(json.id, saveJobs, json);
    }
    case 'ShippingInstructionsDocumentStatusPollJob': {
      return new ShippingInstructionsDocumentStatusPollJob(json.id, saveJobs, json);
    }
    case 'SplitShipmentStatusPollJob': {
      return new SplitShipmentStatusPollJob(json.id, saveJobs, json);
    }
    case 'ContractDocumentStatusPollJob': {
      return new ContractDocumentStatusPollJob(json.id, saveJobs, json);
    }
    default:
      return null;
  }
};

const jobToJSON = (job: JobInstance): BaseJSONRepresentation => {
  return job.toJSON();
};

type JobContextType = {
  createJob: (json: Omit<JobJSON, 'id'>) => JobInstance | undefined;
  cancelJob: (jobId: string) => void;
};

const JobContext = createContext<JobContextType>({
  createJob: () => {
    throw new Error('JobContext not implemented');
  },
  cancelJob: () => {
    throw new Error('JobContext not implemented');
  }
});

const LOCAL_STORAGE_KEY = 'jobs';

const saveJobsToLocalStorage = (jobs: Record<string, JobInstance>) => {
  const stringifiedJobs = JSON.stringify(Object.values(jobs).map(jobToJSON));
  localStorage.setItem(LOCAL_STORAGE_KEY, stringifiedJobs);
};

export const JobProvider: React.ComponentType<React.PropsWithChildren> = ({ children }) => {
  const { addNotification, removeNotification } = useNotifications();

  const saveJobs = useCallback(() => {
    setJobs((jobs) => {
      saveJobsToLocalStorage(jobs);
      return jobs;
    });
  }, []);

  const removeJob = useCallback((jobId: string) => {
    setJobs((jobs) => {
      const newJobs = { ...jobs };
      delete newJobs[jobId];
      return newJobs;
    });
  }, []);

  const onJobStatusChanged = useCallback(
    (jobId: string, _: JobStatus, newStatus: JobStatus) => {
      if (newStatus === 'finished' || newStatus === 'error' || newStatus === 'cancelled') {
        removeJob(jobId);
      }
    },
    [removeJob]
  );

  const createJob = useCallback(
    (json: Omit<JobJSON, 'id'> & { id?: string }) => {
      const withId = { ...json, id: json.id ?? `job_${uuid()}` };
      const jobInstance = jobFromJSON(saveJobs, withId as JobJSON)!;

      if (jobInstance === null) return;

      setJobs((jobs) => ({ ...jobs, [jobInstance.id]: jobInstance }));
      switch (jobInstance.kind) {
        case 'ShipmentDocumentStatusPollJob':
          jobInstance.start({
            addNotification,
            removeNotification
          });
          break;
        case 'ShippingInstructionsDocumentStatusPollJob':
          jobInstance.start({
            addNotification,
            removeNotification
          });
          break;
        case 'SplitShipmentStatusPollJob':
          jobInstance.start({
            addNotification,
            removeNotification
          });
          break;
        case 'ContractDocumentStatusPollJob':
          jobInstance.start({
            addNotification,
            removeNotification
          });
          break;
      }
      jobInstance.onStatusChanged(onJobStatusChanged);
      return jobInstance;
    },
    [onJobStatusChanged, addNotification, saveJobs, removeNotification]
  );

  const loadJobsFromLocalStorage = useCallback(() => {
    const stringifiedJobs = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!stringifiedJobs) {
      return {};
    }
    JSON.parse(stringifiedJobs).forEach((json: JobJSON) => {
      createJob(json);
    });
    setInitiated(true);
  }, [createJob]);

  const [jobs, setJobs] = useState<Record<string, JobInstance>>({});
  const [initiated, setInitiated] = useState(false);

  const cancelJob = useCallback(
    (jobId: string) => {
      const job = jobs[jobId];
      job?.cancel();

      removeJob(jobId);
    },
    [removeJob, jobs]
  );

  useEffect(() => {
    if (!initiated) return;
    saveJobsToLocalStorage(jobs);
  }, [jobs, initiated]);

  useEffect(() => {
    loadJobsFromLocalStorage();
  }, [loadJobsFromLocalStorage]);

  const contextValue = useMemo<JobContextType>(() => ({ createJob, cancelJob }), [createJob, cancelJob]);

  return <JobContext.Provider value={contextValue}>{children}</JobContext.Provider>;
};

export const useJobs = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error('useJobs must be used within a JobProvider');
  }
  return context;
};
