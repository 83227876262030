import { Shipment } from '@myfrey/shipments-types';
import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ShipmentService from '../../services/shipment.service';

type ShipmentState = Shipment[];

const initialState: ShipmentState = [];

export const retrieveShipments = createAsyncThunk<Shipment[], void, { rejectValue: unknown }>(
  'shipments/retrieve',
  async (_, { rejectWithValue }) => {
    try {
      const res = await ShipmentService.getAllShipments();
      return res.data;
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveShipment = createAsyncThunk<Shipment, { id: string }, { rejectValue: unknown }>(
  'shipment/retrieve',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await ShipmentService.get(id);
      return res.data;
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

const shipmentsSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveShipments.fulfilled, (state, action: PayloadAction<Shipment[]>) => {
      return action.payload;
    });
  }
});

const { reducer } = shipmentsSlice;
export default reducer;
