import { Box, CircularProgress, Typography } from '@mui/material';
import MainCard from '../../components/typed/MainCard';
import { useIsAdmin } from '../../hooks/useUserAuth';
import { useNavigate } from 'react-router-dom';
import { WrapperProps } from '../../types/hoc';
// ==============================|| ADMIN GUARD ||============================== //

const AdminGuard = ({ children }: WrapperProps) => {
  const navigate = useNavigate();
  const { isAdmin, isAuthenticating } = useIsAdmin();

  /*useEffect(() => {
    if (!isVerifying && !isAdmin && !isAuthenticating) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [isAdmin]); */

  if (isAdmin) return <>{children}</>;
  if (isAuthenticating)
    return (
      <MainCard>
        <Box data-testid="account-verification-placeholder" sx={{ alignItems: 'center', textAlign: 'center' }}>
          <Typography>Verifying your account</Typography>
          <CircularProgress />
        </Box>
      </MainCard>
    );
  return (
    <MainCard>
      <Box data-testid="account-verification-placeholder" sx={{ alignItems: 'center', textAlign: 'center' }}>
        <Typography>You are not authorized to view this page</Typography>
      </Box>
    </MainCard>
  );
};

export default AdminGuard;
