import http from '../utils/http-common';
import { protectedResources } from '../config';
import { getToken } from './utils';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractSpecialTermsapi.scopes.read);
  return http.get('/contract-special-terms', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractSpecialTermsapi.scopes.read);
  return http.get(`/contract-special-terms/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.contractSpecialTermsapi.scopes.read);
  return http.post('/contract-special-terms', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.contractSpecialTermsapi.scopes.read);
  return http.patch(`/contract-special-terms/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContractSpecialTerm = async (id) => {
  const accessToken = await getToken(protectedResources.contractSpecialTermsapi.scopes.read);
  return http.patch(
    `/contract-special-terms/${id}/soft-delete`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractSpecialTermService = {
  getAll,
  get,
  create,
  update,
  deleteContractSpecialTerm
};

export default ContractSpecialTermService;
