import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DocumentTypeService from '../../services/documenttype.service';

const initialState = [];

export const retrieveDocumentTypes = createAsyncThunk('documenttypes/retrieve', async () => {
  const res = await DocumentTypeService.getAll();
  return res.data;
});

export const updateDocumentType = createAsyncThunk('documenttypes/update', async (payload) => {
  const { id, data } = payload;
  const res = await DocumentTypeService.update(id, data);
  return res.data;
});

export const deleteDocumentType = createAsyncThunk('documenttypess/delete', async (id) => {
  const response = await DocumentTypeService.deleteDocumentType(id);
  return response.data;
});

const DocumentTypeSlice = createSlice({
  name: 'document type',
  initialState,
  extraReducers: {
    [retrieveDocumentTypes.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateDocumentType.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const documentTypeIndex = state.findIndex((documenttype) => documenttype.id === id);
      state = state[documentTypeIndex] = action.payload;
    },
    [deleteDocumentType.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((documenttype) => documenttype.id !== id);
    }
  }
});

const { reducer } = DocumentTypeSlice;
export default reducer;
