import http from '../utils/http-common';
import { protectedResources } from '../config';

import { getToken } from './utils';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.contractPaymentTermsapi.scopes.read);
  return http.get('/contract-payment-terms', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.contractPaymentTermsapi.scopes.read);
  return http.get(`/contract-payment-terms/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data) => {
  const accessToken = await getToken(protectedResources.contractPaymentTermsapi.scopes.read);
  return http.post('/contract-payment-terms', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id, data) => {
  const accessToken = await getToken(protectedResources.contractPaymentTermsapi.scopes.read);
  return http.patch(`/contract-payment-terms/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteContractPaymentTerm = async (id) => {
  const accessToken = await getToken(protectedResources.contractPaymentTermsapi.scopes.read);
  return http.patch(
    `/contract-payment-terms/${id}/soft-delete`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ContractPaymentTermService = {
  getAll,
  get,
  create,
  update,
  deleteContractPaymentTerm
};

export default ContractPaymentTermService;
