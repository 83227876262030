// material-ui
import { alpha } from '@mui/material/styles';
// ==============================|| BUTTON - COLORS ||============================== //
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var main = colors.main, dark = colors.dark, contrastText = colors.contrastText;
    var shadows = theme.customShadows["".concat(color, "Button")];
    return {
        color: contrastText,
        backgroundColor: main,
        boxShadow: shadows,
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: dark
        },
        '&:focus-visible': {
            outline: "2px solid ".concat(dark),
            outlineOffset: 2
        },
        '&::after': {
            borderRadius: '50px',
            boxShadow: "0 0 5px 5px ".concat(alpha(main, 0.9))
        },
        '&:active::after': {
            borderRadius: '50px',
            boxShadow: "0 0 0 0 ".concat(alpha(main, 0.9))
        }
    };
}
// ==============================|| OVERRIDES - BUTTON ||============================== //
export default function Button(theme) {
    return {
        MuiFab: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.grey[200]
                    },
                    '&.MuiFab-primary': getColorStyle({ color: 'primary', theme: theme }),
                    '&.MuiFab-secondary': getColorStyle({ color: 'secondary', theme: theme }),
                    '&.Mui-error': getColorStyle({ color: 'error', theme: theme }),
                    '&.MuiFab-success': getColorStyle({ color: 'success', theme: theme }),
                    '&.MuiFab-info': getColorStyle({ color: 'info', theme: theme }),
                    '&.MuiFab-warning': getColorStyle({ color: 'warning', theme: theme }),
                    '&::after': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 4,
                        opacity: 0,
                        transition: 'all 0.5s'
                    },
                    '&:active::after': {
                        position: 'absolute',
                        borderRadius: 4,
                        left: 0,
                        top: 0,
                        opacity: 1,
                        transition: '0s'
                    }
                }
            }
        }
    };
}
