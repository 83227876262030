import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LogisticsProviderService from '../../services/logisticsserviceprovider.service';

const initialState = [];

export const retrieveLogisticsProviders = createAsyncThunk('logisticsserviceproviders/retrieve', async () => {
  const res = await LogisticsProviderService.getAll();
  return res.data;
});

const LogisticsProviderSlice = createSlice({
  name: 'logisticsserviceprovider',
  initialState,
  extraReducers: {
    [retrieveLogisticsProviders.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = LogisticsProviderSlice;
export default reducer;
