import { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router';

interface DuplicateContractHook {
  canDuplicateContract: boolean;
  duplicateContractHandler: () => void;
}

export const useDuplicateContract = (): DuplicateContractHook => {
  const [canDuplicateContract, setCanEditContract] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const matchedUrl = useMatch('contracts/:id/edit');
  useEffect(() => {
    const isEditUrl = Boolean(matchedUrl);
    const isLocationState = Boolean(location.state);
    const isLocationValid = isEditUrl && isLocationState;
    setCanEditContract(isLocationValid);
  }, [matchedUrl]);
  const duplicateContractHandler = () => {
    //@ts-ignore TODO: add contract type to location state
    const contractDuplicate = { ...location.state.contract, id: undefined };
    navigate(`/contracts/add`, { state: { contract: contractDuplicate } });
  };
  return { canDuplicateContract, duplicateContractHandler };
};
