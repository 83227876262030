import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractSpecialTermService from '../../services/contractspecialterm.service';

const initialState = [];

export const retrieveContractSpecialTerms = createAsyncThunk('contractspecialterms/retrieve', async () => {
  const res = await ContractSpecialTermService.getAll();
  return res.data;
});

export const deleteContractSpecialTerm = createAsyncThunk('contractspecialterm/delete', async (id) => {
  const response = await ContractSpecialTermService.deleteContractSpecialTerm(id);
  return response.data;
});

const ContractSpecialTermSlice = createSlice({
  name: 'contractspecialterm',
  initialState,
  extraReducers: {
    [retrieveContractSpecialTerms.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractSpecialTerm.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractspecial) => contractspecial.id !== id);
    }
  }
});

const { reducer } = ContractSpecialTermSlice;
export default reducer;
