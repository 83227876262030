// change with your own variables
type EnvType = {
  REACT_APP_DVC_CLIENT_ID: string;
  REACT_APP_MSAL_AUTHORITY_ID: string;
  REACT_APP_MSAL_CLIENT_ID: string;
  REACT_APP_MY_FREY_API_BASE_URL: string;
  PLAYWRIGHT_NAME: string;
  PLAYWRIGHT_HOMEPAGE: string;
  REACT_APP_DATADOG_APPLICATION_ID: string;
  REACT_APP_DATADOG_CLIENT_TOKEN: string;
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_VERSION: string;
  REACT_APP_IS_TEST: string;
  PLAYWRIGHT_LOGIN: string;
  PLAYWRIGHT_PASSWORD: string;
};
declare global {
  interface Window {
    env: any;
  }
  interface ImportMeta {
    readonly env: EnvType;
  }
}

export const env: EnvType = { ...import.meta.env, ...window.env };
