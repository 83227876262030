import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FuturesProductService from '../../services/futuresproduct.service';

const initialState = [];

export const retrieveFuturesProducts = createAsyncThunk('futuresproducts/retrieve', async () => {
  const res = await FuturesProductService.getAll();
  return res.data;
});

const FuturesProductSlice = createSlice({
  name: 'futuresproduct',
  initialState,
  extraReducers: {
    [retrieveFuturesProducts.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = FuturesProductSlice;
export default reducer;
