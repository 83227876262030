import http from '../../utils/http-common';
import { protectedResources } from '../../config';
import { getToken } from '../utils';
import { V2 as SaleContractV2 } from '@myfrey/sale-contracts-types';
import { AxiosResponse } from 'axios';

const getSaleContract = async (id: string | number): Promise<AxiosResponse<SaleContractV2.SaleContract>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contracts-refactoring/sale/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const createSaleContract = async (contract: SaleContractV2.SaleContractCreate): Promise<AxiosResponse<SaleContractV2.SaleContract>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.post(`/contracts-refactoring/sale`, contract, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const updateSaleContract = async (
  id: string,
  contract: SaleContractV2.SaleContractUpdate
): Promise<AxiosResponse<SaleContractV2.SaleContract>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.put(`/contracts-refactoring/sale/${id}`, contract, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const deleteSaleContract = async (id: string, headers?: Record<string, string | number>): Promise<AxiosResponse<void>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.delete(`/contracts-refactoring/sale/${id}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const getGeneratePDFStatus = async (
  id: string,
  jobId: string
): Promise<AxiosResponse<{ jobStatus: 'IN_PROGRESS' | 'FAILED' | 'SUCCESS' }>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contracts-refactoring/sale/${id}/generate-pdf/${jobId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const ContractService = {
  getSaleContract,
  createSaleContract,
  updateSaleContract,
  deleteSaleContract,
  getGeneratePDFStatus
};

export default ContractService;
