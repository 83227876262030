import { useRoutes } from 'react-router-dom';
import { lazy } from '../../utils/lazy';

// project import
import MainRoutes from './MainRoutes';
import CommonLayout from '../../layout/CommonLayout';
import Loadable from '../../components/Loadable';

import GuestGuard from '../../utils/route-guard/GuestGuard';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('../../pages/landing')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout layout="landing" />
        </GuestGuard>
      ),
      children: [
        {
          path: '',
          element: <PagesLanding />
        }
      ]
    },
    MainRoutes
  ]);
}
