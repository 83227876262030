import http from '../utils/http-common';

import { getToken } from './utils';

const getAll = async () => {
  const accessToken = await getToken();

  return http.get('/quality-types', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken();

  return http.get(`/quality-types/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const QualityTypeService = {
  getAll,
  get
};

export default QualityTypeService;
