import React, { useEffect, useRef, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';

import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { BellOutlined, CheckCircleOutlined, CheckOutlined } from '@ant-design/icons';

import MainCard from '../../../components/MainCard';
import IconButton from '../../../components/@extended/IconButton';
import Transitions from '../../../components/@extended/Transitions';
import Dialog from '../../../components/Dialog';

import { DialogOptions, useNotifications } from '../../../contexts/NotificationsContext';

import { SplitShipmentDetails } from '../../../pages/Shipments/shipment-details/TabPanels/SplitShipments/components/SplitShipmentDetails';

const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const splitShipmentDetailsComponent = ({
  jobId,
  shipmentId,
  shipmentReference,
  bookingReference
}: {
  jobId: string;
  shipmentId: string;
  shipmentReference: string;
  bookingReference: string;
}) => {
  return (
    <SplitShipmentDetails jobId={jobId} shipmentId={shipmentId} shipmentReference={shipmentReference} bookingReference={bookingReference} />
  );
};

const setDialogFromNotification = ({
  dialogOption,
  jobId,
  shipmentId,
  shipmentReference,
  bookingReference
}: {
  dialogOption: DialogOptions;
  jobId: string;
  shipmentId: string;
  shipmentReference: string;
  bookingReference: string;
}): React.ReactElement => {
  switch (dialogOption) {
    case 'split-shipment-details':
      return splitShipmentDetailsComponent({ jobId, shipmentId, shipmentReference, bookingReference });
  }
};

const Notifications = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const { notifications, markNotificationAsRead, removeNotification } = useNotifications();

  const anchorRef = useRef<any>(null);

  const read = notifications.filter((notification) => !notification.read).length;

  const [open, setOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialog, setDialog] = useState<React.ReactElement>(<div></div>);
  const handleToggle = () => {
    setOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      notifications.forEach((notification) => {
        if (!notification.read) {
          markNotificationAsRead(notification.id);
        }
      });
    }
  }, [notifications, open, markNotificationAsRead]);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Dialog open={openDialog} setOpen={setOpenDialog} testId="notification-details">
        <div>{dialog}</div>
      </Dialog>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : 'transparent' }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={read} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          // @ts-ignore
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper
              sx={{
                // @ts-ignore
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {read > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton color="success" size="small" onClick={() => {}}>
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                    {[...notifications]
                      .reverse()
                      .map((notification) => {
                        const formattedDate = formatDistanceToNow(new Date(notification.timestamp), { addSuffix: true });
                        return (
                          <ListItemButton key={notification.id} selected={notification.read}>
                            <ListItemAvatar>
                              <Avatar
                                sx={
                                  notification.type === 'success'
                                    ? { color: 'success.main', bgcolor: 'success.lighter' }
                                    : notification.type === 'info'
                                    ? {
                                        color: 'info.main',
                                        bgcolor: 'info.lighter'
                                      }
                                    : { color: 'error.main', bgcolor: 'error.lighter' }
                                }
                              >
                                <CheckOutlined />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Typography variant="h6">{notification.message}</Typography>}
                              secondary={formattedDate}
                            />
                            <ListItemSecondaryAction sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                              {notification.refUrl && (
                                <Typography variant="caption" noWrap>
                                  <a href={notification.refUrl.url}>{notification.refUrl.label}</a>
                                </Typography>
                              )}
                              {notification.detailsInDialog && (
                                <Box
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setDialog(
                                      setDialogFromNotification({
                                        dialogOption: notification.detailsInDialog!.dialog,
                                        jobId: notification.detailsInDialog!.jobId,
                                        shipmentId: notification.detailsInDialog!.shipmentId,
                                        shipmentReference: notification.detailsInDialog!.shipmentReference,
                                        bookingReference: notification.detailsInDialog!.bookingReference
                                      })
                                    );
                                  }}
                                >
                                  <Typography variant="caption" noWrap>
                                    {notification.detailsInDialog.label}
                                  </Typography>
                                </Box>
                              )}
                              <Typography
                                onClick={() => {
                                  removeNotification(notification.id);
                                }}
                                variant="caption"
                                noWrap
                              >
                                Clear notification
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        );
                      })
                      .flatMap((notification, index) => (index === 0 ? [notification] : [notification, <Divider key={index} />]))}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notifications;
