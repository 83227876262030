var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// ==============================|| BADGE - COLORS ||============================== //
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var lighter = colors.lighter, main = colors.main;
    return {
        color: main,
        backgroundColor: lighter
    };
}
// ==============================|| OVERRIDES - BADGE ||============================== //
export default function Badge(theme) {
    var defaultLightBadge = getColorStyle({ color: 'primary', theme: theme });
    return {
        MuiBadge: {
            styleOverrides: {
                standard: {
                    minWidth: theme.spacing(2),
                    height: theme.spacing(2),
                    padding: theme.spacing(0.5)
                },
                light: __assign(__assign({}, defaultLightBadge), { '&.MuiBadge-colorPrimary': getColorStyle({ color: 'primary', theme: theme }), '&.MuiBadge-colorSecondary': getColorStyle({ color: 'secondary', theme: theme }), '&.MuiBadge-colorError': getColorStyle({ color: 'error', theme: theme }), '&.MuiBadge-colorInfo': getColorStyle({ color: 'info', theme: theme }), '&.MuiBadge-colorSuccess': getColorStyle({ color: 'success', theme: theme }), '&.MuiBadge-colorWarning': getColorStyle({ color: 'warning', theme: theme }) })
            }
        }
    };
}
