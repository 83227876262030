import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// project import
import componentsOverride from './overrides';
import Palette from './palette';
import CustomShadows from './shadows';
import Typography from './typography';
// ==============================|| DEFAULT THEME - MAIN ||============================== //
export default function ThemeCustomization(_a) {
    var children = _a.children, themeDirection = _a.themeDirection, mode = _a.mode, presetColor = _a.presetColor, fontFamily = _a.fontFamily;
    var theme = useMemo(function () { return Palette(mode, presetColor); }, [mode, presetColor]);
    var themeTypography = useMemo(function () { return Typography(fontFamily); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode, fontFamily]);
    var themeCustomShadows = useMemo(function () { return CustomShadows(theme.palette); }, [theme]);
    var themeOptions = useMemo(function () { return ({
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1024,
                lg: 1266,
                xl: 1440
            }
        },
        direction: themeDirection,
        mixins: {
            toolbar: {
                minHeight: 60,
                paddingTop: 8,
                paddingBottom: 8
            }
        },
        palette: theme.palette,
        customShadows: themeCustomShadows,
        typography: themeTypography
    }); }, [themeDirection, theme, themeTypography, themeCustomShadows]);
    var themes = createTheme(themeOptions);
    themes.components = componentsOverride(themes);
    return (_jsx(StyledEngineProvider, { injectFirst: true, children: _jsxs(ThemeProvider, { theme: themes, children: [_jsx(CssBaseline, {}), children] }) }));
}
