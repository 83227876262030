import http from '../utils/http-common';
import { protectedResources } from '../config';
import { getToken } from './utils';
import type { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';

export type Trader = {
  id: number;
  traderId: string;
  initials: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
};

const getAll = async () => {
  const accessToken = await getToken(protectedResources.traderapi.scopes.read);
  return http.get<Trader[]>('/traders', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const search = async (params?: SearchParams) => {
  const defaultParams = {
    q: '*'
  };

  const preparedParams = Object.assign({}, defaultParams, params);
  const accessToken = await getToken(protectedResources.traderapi.scopes.read);

  return http.get<SearchResponse<Trader>>('/traders', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: preparedParams
  });
};

const get = async (id: string) => {
  const accessToken = await getToken(protectedResources.traderapi.scopes.read);
  return http.get<Trader>(`/traders/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data: Omit<Trader, 'createdAt' | 'updatedAt'>) => {
  const accessToken = await getToken(protectedResources.traderapi.scopes.read);
  return http.post('/traders', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id: string, data: Omit<Trader, 'id' | 'createdAt' | 'updatedAt'>) => {
  const accessToken = await getToken(protectedResources.traderapi.scopes.read);
  return http.patch(`/traders/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteTrader = async (id: string) => {
  const accessToken = await getToken(protectedResources.traderapi.scopes.read);

  return http.patch(
    `/traders/${id}`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const TraderService = {
  getAll,
  search,
  get,
  create,
  update,
  deleteTrader
};

export default TraderService;
