import http from '../utils/http-common';

import { protectedResources } from '../config';
import { getToken } from './utils';
import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents';

export type Product = {
  id: number;
  productId: string;
  countryCode: string;
  productTypeId: string;
  productCategoryId: string;
  productName: string;
  productShortName: string;
  hsCode: string;
  grade: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  tblCountries: Array<{
    id: number;
    countryCode: string;
    countryName: string;
    createdAt: string;
    updatedAt: string;
  }>;
  tblProductType: Array<{
    id: number;
    productTypeId: string;
    productTypeName: string;
    productTypeDescription: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
  }>;
  tblProductCategory: Array<{
    id: number;
    productCategoryId: string;
    productCategoryName: string;
    productCategoryDescription: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
  }>;
};

type ProductCreateOrUpdate = Pick<
  Product,
  'countryCode' | 'productTypeId' | 'productCategoryId' | 'productName' | 'productShortName' | 'isActive'
>;

const getAll = async () => {
  const accessToken = await getToken(protectedResources.productsapi.scopes.read);
  return http.get<Product[]>('/products', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const search = async (params?: SearchParams) => {
  const defaultParams = {
    q: '*'
  };

  const preparedParams = Object.assign({}, defaultParams, params);
  const accessToken = await getToken(protectedResources.productsapi.scopes.read);

  return http.get<SearchResponse<Product>>('/products', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: preparedParams
  });
};

const get = async (id: string) => {
  const accessToken = await getToken(protectedResources.productsapi.scopes.read);

  return http.get<Product>(`/products/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const create = async (data: ProductCreateOrUpdate) => {
  const accessToken = await getToken(protectedResources.productsapi.scopes.read);

  return http.post<Product>('/products', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const update = async (id: string, data: ProductCreateOrUpdate) => {
  const accessToken = await getToken(protectedResources.productsapi.scopes.read);

  return http.patch<Product>(`/products/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const deleteProduct = async (id: string) => {
  const accessToken = await getToken(protectedResources.productsapi.scopes.read);

  return http.patch<Product>(
    `/products/${id}`,
    { isActive: false },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const ProductService = {
  getAll,
  get,
  search,
  create,
  update,
  deleteProduct
};

export default ProductService;
