import http from '../utils/http-common';

import { protectedResources } from '../config';
import { getToken } from './utils';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.documentissuersapi.scopes.read);

  return http.get('/document-issuers', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.documentissuersapi.scopes.read);

  return http.get(`/document-issuers/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const DocumentIssuerService = {
  getAll,
  get
};

export default DocumentIssuerService;
