import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractService from '../../services/contractnew.service';

const initialState = [];

export const retrieveContracts = createAsyncThunk('contracts/retrieve', async (_, { rejectWithValue }) => {
  try {
    const res = await ContractService.getAll();
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const retrieveSalesContracts = createAsyncThunk('contracts/retrieve-sales', async ({ rejectWithValue }) => {
  try {
    const res = await ContractService.getSalesContracts();
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const retrieveContract = createAsyncThunk('contract/retrieve', async (id, { rejectWithValue }) => {
  try {
    const res = await ContractService.get(id);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateContract = createAsyncThunk('contract/update', async (payload) => {
  const { id, data } = payload;
  const res = await ContractService.update(id, data);
  return res.data;
});

export const deleteContract = createAsyncThunk('contract/delete', async (id) => {
  const response = await ContractService.deleteContract(id);
  return response.data;
});

const ContractSlice = createSlice({
  name: 'contract',
  initialState,
  extraReducers: {
    [retrieveContracts.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateContract.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const contractIndex = state.findIndex((contract) => contract.id === id);
      state = state[contractIndex] = action.payload;
    },
    [deleteContract.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contract) => contract.id !== id);
    }
  }
});

const { reducer } = ContractSlice;
export default reducer;
