// third-party
import { combineReducers } from 'redux';

// project import

import menu from './menu';
import snackbar from './snackbar';

import contractReducer from './contract';
import countryReducer from './country';
import productReducer from './product';
import productcategoryReducer from './productcategory';
import producttypeReducer from './producttype';
import qualitycategoryReducer from './qualitycategory';
import qualityoperatorReducer from './qualityoperator';
import qualitytypeReducer from './qualitytype';
import qualityspecReducer from './qualityspec';
import documenttypeReducer from './documenttype';
import companyReducer from './company';
import contractnewReducer from './contractnew';
import referenceReducer from './reference';
import contractdocumentReducer from './contractdocument';
import documentissuerReducer from './documentissuer';
import displayedaddressReducer from './displayedaddress';
import traderReducer from './trader';
import paymentmethodReducer from './paymentmethod';
import contractproductReducer from './contractproduct';
import deliverytermReducer from './deliveryterm';
import futurescontractReducer from './futurescontract';
import futuresexchangeReducer from './futuresexchange';
import futuresproductReducer from './futuresproduct';
import locationReducer from './location';
import currencyReducer from './currency';
import contractlocationoptionReducer from './contractlocationoption';
import brokerReducer from './broker';
import contractqualityspecReducer from './contractqualityspec';
import contractpaymenttermReducer from './contractpaymentterm';
import contractspecialtermReducer from './contractspecialterm';
import shipmentperiodReducer from './shipmentperiod';
import contractshipmentReducer from './contractshipment';
import equipmentReducer from './equipment';
import contractpackingoptionReducer from './contractpackingoption';
import packingweightoptionReducer from './packingweightoption';

import contractcarrierReducer from './contractcarrier';
import contractproviderReducer from './contractlogisticsserviceprovider';
import logisticsserviceproviderReducer from './logisticsserviceprovider';
import carrierReducer from './carrier';
import containerfacilitiyReducer from './containerfacility';
import bookingReducer from './booking';
import transportscheduleReducer from './transportschedule';
import shipmentReducer from './shipment';
import contractCostReducer from './contractCost';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  contract: contractReducer,
  country: countryReducer,
  product: productReducer,
  productcategory: productcategoryReducer,
  producttype: producttypeReducer,
  qualitycategory: qualitycategoryReducer,
  qualityoperator: qualityoperatorReducer,
  qualitytype: qualitytypeReducer,
  qualityspec: qualityspecReducer,
  documenttype: documenttypeReducer,
  company: companyReducer,
  contractnew: contractnewReducer,
  reference: referenceReducer,
  contractdocument: contractdocumentReducer,
  documentissuer: documentissuerReducer,
  displayedaddress: displayedaddressReducer,
  trader: traderReducer,
  paymentmethod: paymentmethodReducer,
  contractproduct: contractproductReducer,
  deliveryterm: deliverytermReducer,
  futurescontract: futurescontractReducer,
  futuresexchange: futuresexchangeReducer,
  futuresproduct: futuresproductReducer,
  location: locationReducer,
  currency: currencyReducer,
  contractlocationoption: contractlocationoptionReducer,
  broker: brokerReducer,
  contractqualityspec: contractqualityspecReducer,
  contractpaymentterm: contractpaymenttermReducer,
  contractspecialterm: contractspecialtermReducer,
  shipmentperiod: shipmentperiodReducer,
  contractshipment: contractshipmentReducer,
  equipment: equipmentReducer,
  contractpackingoption: contractpackingoptionReducer,
  packingweightoption: packingweightoptionReducer,
  contractcarrier: contractcarrierReducer,
  contractprovider: contractproviderReducer,
  logisticsserviceprovider: logisticsserviceproviderReducer,
  carrier: carrierReducer,
  containerfacilitiy: containerfacilitiyReducer,
  booking: bookingReducer,
  transportschedule: transportscheduleReducer,
  shipment: shipmentReducer,
  contractCost: contractCostReducer
});

export default reducers;
