import { JobBase } from './JobBase';
import { CreateNotificationDefinition } from '../NotificationsContext';
import { pollDocumentGenerationStatus } from '../../pages/Shipments/shipment-details/TabPanels/ShipmentFileManagement/pollDocumentGenerationStatus';
import ShippingInstructionsService from '../../services/shippingInstructions.service';

type ShippingInstructionsDocumentStatusPollJobJSON = {
  id: string;
  kind: 'ShippingInstructionsDocumentStatusPollJob';
  shipmentId: string;
  shippingInstructionReference: string;
  jobId: string;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProcessNotificationId?: string;
};
type ShippingInstructionsDocumentStatusPollJobStartArgs = {
  addNotification: (notification: CreateNotificationDefinition) => string;
  removeNotification: (notificationId: string) => void;
};
class ShippingInstructionsDocumentStatusPollJob extends JobBase<
  ShippingInstructionsDocumentStatusPollJobJSON,
  ShippingInstructionsDocumentStatusPollJobStartArgs
> {
  kind = 'ShippingInstructionsDocumentStatusPollJob';
  status = 'pending' as const;

  shipmentId: string;
  shippingInstructionReference: string;
  jobId: string;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProcessNotificationId?: string;

  constructor(id: string, saveJobs: () => void, json: ShippingInstructionsDocumentStatusPollJobJSON) {
    super(id, saveJobs);

    this.shipmentId = json.shipmentId;
    this.jobId = json.jobId;
    this.shippingInstructionReference = json.shippingInstructionReference;
    this.onTimeout = json.onTimeout;
    this.onOk = json.onOk;
    this.onError = json.onError;
    this.inProcessNotificationId = json.inProcessNotificationId;
  }

  start = ({ addNotification, removeNotification }: ShippingInstructionsDocumentStatusPollJobStartArgs) => {
    this.setStatus('running');
    const { shipmentId, shippingInstructionReference, jobId, onTimeout, onOk, onError } = this;
    pollDocumentGenerationStatus(
      () => ShippingInstructionsService.getGenerateShippingInstructionsDocumentStatus(shipmentId, shippingInstructionReference, jobId),
      0,
      {
        onTimeout: () => {
          if (this.inProcessNotificationId) return;

          onTimeout?.();
          this.inProcessNotificationId = addNotification({
            message: `Shipping instructions PDF generation in progress...`,
            type: 'info',
            refUrl: {
              label: "Got to shipment's details",
              url: `/shipments/${shipmentId}/details`
            }
          });
          this.saveJobs();
        },
        onOk: () => {
          onOk?.();
          addNotification({
            message: `Shipping instructions PDF was generated successfully!`,
            refUrl: {
              label: "Got to shipment's details",
              url: `/shipments/${shipmentId}/details`
            },
            type: 'success'
          });
          if (this.inProcessNotificationId) removeNotification(this.inProcessNotificationId);
          this.setStatus('finished');
        },
        onError: () => {
          onError?.();
          addNotification({
            message: `Shipping instructions PDF failed to generate`,
            refUrl: {
              label: "Got to shipment's details",
              url: `/shipments/${shipmentId}/details`
            },
            type: 'error'
          });
          if (this.inProcessNotificationId) removeNotification(this.inProcessNotificationId);
          this.setStatus('error');
        }
      }
    );
  };

  cancel = () => {
    this.setStatus('cancelled');
  };

  toJSON = () => {
    return {
      id: this.id,
      kind: this.kind as 'ShippingInstructionsDocumentStatusPollJob',
      shipmentId: this.shipmentId,
      shippingInstructionReference: this.shippingInstructionReference,
      jobId: this.jobId,
      inProcessNotificationId: this.inProcessNotificationId
    };
  };
}

export { ShippingInstructionsDocumentStatusPollJob };
export type { ShippingInstructionsDocumentStatusPollJobJSON };
