import { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router';

interface GoToContractHook {
  canNavigateToDetails: boolean;
  navigateToContractDetailsHandler: () => void;
}

export const useGoToContractDetails = (): GoToContractHook => {
  const [canNavigateToDetails, setCanNavigateToDetails] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const matchedUrl = useMatch('contracts/:id/edit');
  useEffect(() => {
    const isEditUrl = Boolean(matchedUrl);
    const isLocationState = Boolean(location.state);
    const isLocationValid = isEditUrl && isLocationState;
    setCanNavigateToDetails(isLocationValid);
  }, [matchedUrl, location.state]);
  const navigateToContractDetailsHandler = () => {
    if (!canNavigateToDetails || !location.state.contract.id) return;
    navigate(`/contracts/${location.state.contract.id}/details`);
  };
  return { canNavigateToDetails, navigateToContractDetailsHandler };
};
