import { JobBase } from './JobBase';
import { CreateNotificationDefinition } from '../NotificationsContext';
import { pollDocumentGenerationStatus } from '../../pages/Shipments/shipment-details/TabPanels/ShipmentFileManagement/pollDocumentGenerationStatus';
import ShipmentService from '../../services/shipment.service';
import { getFeatureFlagValue } from '../../services/utils';

type SplitShipmentStatusPollJobJSON = {
  id: string;
  kind: 'SplitShipmentStatusPollJob';
  shipmentId: string;
  shipmentReference: string;
  bookingReference: string;
  jobId: string;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProcessNotificationId?: string;
};
type SplitShipmentStatusPollJobStartArgs = {
  addNotification: (notification: CreateNotificationDefinition) => string;
  removeNotification: (notificationId: string) => void;
};
class SplitShipmentStatusPollJob extends JobBase<SplitShipmentStatusPollJobJSON, SplitShipmentStatusPollJobStartArgs> {
  kind = 'SplitShipmentStatusPollJob';
  status = 'pending' as const;

  shipmentId: string;
  shipmentReference: string;
  bookingReference: string;
  jobId: string;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProcessNotificationId?: string;

  constructor(id: string, saveJobs: () => void, json: SplitShipmentStatusPollJobJSON) {
    super(id, saveJobs);

    this.shipmentId = json.shipmentId;
    this.shipmentReference = json.shipmentReference;
    this.bookingReference = json.bookingReference;
    this.jobId = json.jobId;
    this.onTimeout = json.onTimeout;
    this.onOk = json.onOk;
    this.onError = json.onError;
    this.inProcessNotificationId = json.inProcessNotificationId;
  }

  start = async ({ addNotification, removeNotification }: SplitShipmentStatusPollJobStartArgs) => {
    this.setStatus('running');
    const { shipmentId, jobId, onTimeout, onOk, onError, bookingReference, shipmentReference } = this;
    pollDocumentGenerationStatus(() => ShipmentService.getCreateSplitShipmentJobStatus(shipmentId, jobId), 0, {
      onTimeout: async () => {
        const notificationsDialogFF = await getFeatureFlagValue('notifications-dialog', false);
        if (this.inProcessNotificationId) return;
        console.log('Timeout');
        onTimeout?.();
        this.inProcessNotificationId = addNotification({
          message: `Split shipment in progress...`,
          type: 'info',
          ...(notificationsDialogFF
            ? {
                detailsInDialog: {
                  label: 'Go to split shipment details',
                  dialog: 'split-shipment-details',
                  jobId,
                  shipmentId,
                  shipmentReference,
                  bookingReference
                }
              }
            : { refUrl: { label: "Go to shipment's details", url: `/shipments/${shipmentId}/details` } })
        });
        this.saveJobs();
      },
      onOk: async () => {
        const notificationsDialogFF = await getFeatureFlagValue('notifications-dialog', false);
        console.log('Ok');
        onOk?.();
        addNotification({
          message: `Split shipment was successful!`,
          ...(notificationsDialogFF
            ? {
                detailsInDialog: {
                  label: 'Go to split shipment details',
                  dialog: 'split-shipment-details',
                  jobId,
                  shipmentId,
                  shipmentReference,
                  bookingReference
                }
              }
            : { refUrl: { label: "Go to shipment's details", url: `/shipments/${shipmentId}/details` } }),
          type: 'success'
        });
        if (this.inProcessNotificationId) removeNotification(this.inProcessNotificationId);
        this.setStatus('finished');
      },
      onError: async () => {
        const notificationsDialogFF = await getFeatureFlagValue('notifications-dialog', false);
        console.log('error');
        onError?.();
        addNotification({
          message: `Failed split shipment`,
          ...(notificationsDialogFF
            ? {
                detailsInDialog: {
                  label: 'Go to split shipment details',
                  dialog: 'split-shipment-details',
                  jobId,
                  shipmentId,
                  shipmentReference,
                  bookingReference
                }
              }
            : { refUrl: { label: "Go to shipment's details", url: `/shipments/${shipmentId}/details` } }),
          type: 'error'
        });
        if (this.inProcessNotificationId) removeNotification(this.inProcessNotificationId);
        this.setStatus('error');
      }
    });
  };

  cancel = () => {
    this.setStatus('cancelled');
  };

  toJSON = () => {
    return {
      id: this.id,
      kind: this.kind as 'SplitShipmentStatusPollJob',
      shipmentId: this.shipmentId,
      shipmentReference: this.shipmentReference,
      bookingReference: this.bookingReference,
      jobId: this.jobId,
      inProcessNotificationId: this.inProcessNotificationId
    };
  };
}

export { SplitShipmentStatusPollJob };
export type { SplitShipmentStatusPollJobJSON };
