import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractPaymentTermService from '../../services/contractpaymentterm.service';

const initialState = [];

export const retrieveContractPaymentTerms = createAsyncThunk('contractpaymentterms/retrieve', async () => {
  const res = await ContractPaymentTermService.getAll();
  return res.data;
});

export const deleteContractPaymentTerm = createAsyncThunk('contractpaymentterms/delete', async (id) => {
  const response = await ContractPaymentTermService.deleteContractPaymentTerm(id);
  return response.data;
});

const ContractPaymentTermSlice = createSlice({
  name: 'contractpaymentterm',
  initialState,
  extraReducers: {
    [retrieveContractPaymentTerms.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractPaymentTerm.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractpayment) => contractpayment.id !== id);
    }
  }
});

const { reducer } = ContractPaymentTermSlice;
export default reducer;
