import { ForwardedRef, ReactNode, forwardRef } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// header style
const headerSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

type MainCardProps = { content?: boolean; title?: string; divider?: boolean; children: ReactNode };

const MainCard = forwardRef(({ content = true, title, divider, children }: MainCardProps, ref?: ForwardedRef<HTMLDivElement>) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      ref={ref}
      sx={{
        position: 'relative',
        border: '1px solid',
        borderRadius: 1,
        //@ts-ignore TODO: define types for theme
        borderColor: theme.palette.grey.A800,
        boxShadow: 'inherit',
        '& pre': {
          m: 0,
          p: '12px !important',
          fontFamily: theme.typography.fontFamily,
          fontSize: '0.75rem'
        }
      }}
    >
      {/* card header and action */}
      {title && <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={title} />}

      {/* content & header divider */}
      {title && divider && <Divider />}

      {/* card content */}
      {content && <CardContent>{children}</CardContent>}
      {!content && children}
    </Card>
  );
});

export default MainCard;
