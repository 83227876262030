import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CarrierService from '../../services/carrier.service';

const initialState = [];

export const retrieveCarriers = createAsyncThunk('carriers/retrieve', async () => {
  const res = await CarrierService.getAll();
  return res.data;
});

const CarrierSlice = createSlice({
  name: 'carrier',
  initialState,
  extraReducers: {
    [retrieveCarriers.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = CarrierSlice;
export default reducer;
