import http from '../utils/http-common';
import { protectedResources } from '../config';
import { getToken } from './utils';

const getAll = async () => {
  const accessToken = await getToken(protectedResources.futuresContractsapi.scopes.read);
  return http.get('/futures-contracts', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken(protectedResources.futuresContractsapi.scopes.read);
  return http.get(`/futures-contracts/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const FuturesContractService = {
  getAll,
  get
};

export default FuturesContractService;
