import PropTypes from 'prop-types';
import { Link, useLocation, useMatch } from 'react-router-dom';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Button, Divider, Grid, Typography } from '@mui/material';

import MainCard from '../MainCard';

import { useDuplicateContract } from '../../hooks/useCanDuplicateContract';
import { useGetCrumbs } from '../../hooks/useGetBreadcrumbs';
import { useGoToContractDetails } from '../../hooks/useGoToContractDetails';

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}) => {
  const location = useLocation();
  const { main, item, hideMainCard } = useGetCrumbs();

  const { canDuplicateContract, duplicateContractHandler } = useDuplicateContract();
  const { canNavigateToDetails, navigateToContractDetailsHandler: navigateToContractHandler } = useGoToContractDetails();

  // only used for component demo breadcrumbs
  if (location.pathname === '/components-overview/breadcrumbs') {
    location.pathname = '/dashboard/analytics';
  }

  // item separator
  const SeparatorIcon = separator;
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  let breadcrumbContent = <Typography />;
  const marginSX = hideMainCard ? {} : { mb: 3 };

  // main
  if (item) {
    breadcrumbContent = (
      <MainCard
        border={card}
        sx={card === false ? { ...marginSX, bgcolor: 'transparent', ...sx } : { ...marginSX, ...sx }}
        {...others}
        content={card}
        shadow="none"
      >
        <Grid
          container
          direction={rightAlign ? 'row' : 'column'}
          justifyContent={rightAlign ? 'space-between' : 'flex-start'}
          alignItems={rightAlign ? 'center' : 'flex-start'}
          spacing={1}
        >
          <Grid item>
            <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
              {main.map((breadcrumb, i) => {
                const isLast = i + 1 == main.length;
                const crumbColor = isLast ? 'textPrimary' : 'textSecondary';
                const linkProps = isLast || !breadcrumb.link ? {} : { component: Link, to: breadcrumb.link };
                return (
                  // eslint-disable-next-line react/jsx-key
                  <Typography color={crumbColor} variant="h6" sx={{ textDecoration: 'none' }} {...linkProps}>
                    {breadcrumb.title}
                  </Typography>
                );
              })}
            </MuiBreadcrumbs>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ mt: card === false ? 0.25 : 1 }}
          >
            <Grid item sx={{ ml: 1 }}>
              {!hideMainCard && (
                <Typography variant="h2" data-testid={`page-title-${item.toLowerCase()}`}>
                  {item}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{ mt: card === false ? 0.25 : 1 }}
              >
                {canDuplicateContract && (
                  <Grid item>
                    <Button variant="contained" sx={{ textTransform: 'none' }} onClick={duplicateContractHandler}>
                      {'Duplicate'}
                    </Button>
                  </Grid>
                )}
                {canNavigateToDetails && (
                  <Grid item>
                    <Button variant="contained" sx={{ textTransform: 'none' }} onClick={navigateToContractHandler}>
                      {'Details'}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
      </MainCard>
    );
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.object,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Breadcrumbs;
