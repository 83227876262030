import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import QualityOperatorService from '../../services/qualityoperator.service';

const initialState = [];

export const retrieveQualityOperators = createAsyncThunk('qualityoperators/retrieve', async () => {
  const res = await QualityOperatorService.getAll();
  return res.data;
});

const qualityoperatorSlice = createSlice({
  name: 'qualityoperator',
  initialState,
  extraReducers: {
    [retrieveQualityOperators.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = qualityoperatorSlice;
export default reducer;
