import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RouteScheduleService from '../../services/scheduletransport.service';

const initialState = [];

export const retrieveSchedules = createAsyncThunk('schedules/retrieve', async (params) => {
  const res = await RouteScheduleService.getRouteSchedules(params);
  return res.data;
});

const ScheduleSlice = createSlice({
  name: 'transportschedule',
  initialState,
  extraReducers: {
    [retrieveSchedules.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = ScheduleSlice;
export default reducer;
