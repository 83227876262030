import { Typography, CircularProgress, Box, Alert, Paper } from '@mui/material';
import ShipmentService from '../../../../../../services/shipment.service';
import { useQuery } from '@tanstack/react-query';

const muiAlertSeverity = ['success', 'info', 'warning', 'error'] as const;
type MuiAlertSeverity = (typeof muiAlertSeverity)[number];
const taskStatus = ['SUCCESS', 'FAILED', 'IN_PROGRESS'] as const;
type TaskStatus = (typeof taskStatus)[number];

const mapTaskStatusToMuiSeverity = (status: TaskStatus): MuiAlertSeverity => {
  if (status === 'SUCCESS') return 'success';
  else if (status === 'FAILED') return 'error';
  else if (status === 'IN_PROGRESS') return 'info';
  console.error('Unknown status in task statuses');
  return 'info';
};

export const SplitShipmentDetails: React.FunctionComponent<{
  shipmentId: string;
  jobId: string;
  shipmentReference: string;
  bookingReference: string;
}> = ({ shipmentId, jobId, shipmentReference, bookingReference }) => {
  const { data: details, isLoading: isLoading } = useQuery({
    queryFn: async () => {
      return await ShipmentService.getSplitShipmentJobDetails(shipmentId, jobId);
    },
    queryKey: [`split-shipment-details-${shipmentId}-${jobId}`]
  });
  return (
    <>
      <Typography variant="h3" component="div" sx={{ mb: 2 }}>
        Split shipment details | Shipment: {shipmentReference} | Booking: {bookingReference}
      </Typography>
      {isLoading ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Alert severity="success" sx={{ mr: 1 }}>
              Success!
            </Alert>
            <Alert severity="info" sx={{ mr: 1 }}>
              In progress, please check again later.
            </Alert>
            <Alert severity="error" sx={{ mr: 1 }}>
              Failed, there is a need to reproduce this step manually.
            </Alert>
          </Box>
          <Typography variant="h5">Status of full process: {details?.data?.descriptionResult?.status}</Typography>
          {details?.data?.tasks && <Typography variant="h5">Details: </Typography>}
          {details?.data?.tasks?.map((task: any) => (
            <Alert key={task.lambdaName} severity={mapTaskStatusToMuiSeverity(task.taskStatus)} sx={{ mb: 1 }}>
              {task.taskName}
              {task?.payload?.newBookingNumber ? `, new booking number: ${task.payload.newBookingNumber}` : ''}
              {task?.payload?.newShipmentNumber ? `, new shipment number: ${task.payload.newShipmentNumber}` : ''}
            </Alert>
          ))}
        </>
      )}
    </>
  );
};
