import { useState, useEffect, useCallback } from 'react';
import { Alert, AlertColor, AlertTitle, Grow } from '@mui/material';
import { useSnackbar } from '../contexts/SnackbarContext';

type SnackbarToastProps = {
  toast: {
    id: string;
    severity?: AlertColor;
    title?: string;
    message?: string;
    duration?: number;
    onClose?: () => void;
    children?: React.ReactNode;
  };
};

const TIME_FOR_TOAST_TO_ANIMATE_AWAY = 300;
const DEFAULT_DURATION = 6000;

const SnackbarToast: React.FC<SnackbarToastProps> = ({ toast }) => {
  const [open, setOpen] = useState(true);
  const { removeSnackbar } = useSnackbar();

  const close = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      removeSnackbar(toast.id);
    }, TIME_FOR_TOAST_TO_ANIMATE_AWAY);
  }, [toast.id, removeSnackbar]);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (toast?.onClose) {
      toast.onClose();
    }
    close();
  };

  useEffect(() => {
    if (toast.duration !== 0) {
      setTimeout(() => {
        close();
      }, toast.duration || DEFAULT_DURATION);
    }
  }, [close, toast.duration]);

  return (
    <Grow in={open} timeout={TIME_FOR_TOAST_TO_ANIMATE_AWAY}>
      <Alert
        key={toast.id}
        severity={toast?.severity || 'info'}
        onClose={handleClose}
        variant="filled"
        sx={{
          minWidth: 280,
          width: { xs: 1, md: 'auto' },
          mb: 1
        }}
      >
        {toast?.title && <AlertTitle>{toast.title}</AlertTitle>}
        {toast?.message}
        {toast?.children}
      </Alert>
    </Grow>
  );
};

export default SnackbarToast;
