import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import MuiSnackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import React from 'react';

type SnackbarProps = Parameters<typeof MuiSnackbar>[0];
type AlertProps = Parameters<typeof Alert>[0];

type SharedSnackbarState = {
  action: boolean;
  open: boolean;
  message: string;
  anchorOrigin: SnackbarProps['anchorOrigin'];
  transition: 'Fade' | 'Grow' | 'SlideDown' | 'SlideRight' | 'SlideUp' | 'SlideLeft';
  close: boolean;
  actionButton: boolean | { text: string; url: string };
  timeout: number;
} & Pick<SnackbarProps, 'anchorOrigin'>;

type SnackbarStateDefault = {
  variant: 'default';
} & SharedSnackbarState;

type SnackbarStateAlert = {
  variant: 'alert';
  alert: Pick<AlertProps, 'color' | 'variant' | 'severity'>;
} & SharedSnackbarState;

type SnackbarState = SnackbarStateAlert | SnackbarStateDefault;

const defaultAlert = {
  color: 'primary',
  variant: 'filled',
  severity: 'success'
};
const initialState: SnackbarState = {
  action: false,
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  variant: 'default',
  transition: 'Fade',
  close: true,
  actionButton: false,
  timeout: 6000
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, action: { type: string; payload: Partial<SnackbarState> }) {
      const { open, message, anchorOrigin, variant, transition, close, actionButton, timeout } = action.payload;

      const newState: SnackbarState = merge(
        { ...initialState },
        {
          action: !state.action,
          open,
          message,
          anchorOrigin,
          variant,
          alert: (action.payload.variant === 'alert' ? action.payload.alert : undefined) ?? defaultAlert,
          transition,
          close,
          actionButton,
          timeout
        }
      );
      // @ts-ignore Object.entries squashes keyof to string
      Object.entries(newState).forEach(([key, value]) => (state[key] = value));
    },

    closeSnackbar(state) {
      state.open = false;
    }
  }
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar } = snackbar.actions;
