import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DisplayedAddressService from '../../services/displayedaddress.service';

const initialState = [];

export const retrieveDisplayedAddresses = createAsyncThunk('displayedaddresses/retrieve', async () => {
  const res = await DisplayedAddressService.getAll();
  return res.data;
});

export const updateDisplayedAddress = createAsyncThunk('displayedaddress/update', async (payload) => {
  const { id, data } = payload;
  const res = await DisplayedAddressService.update(id, data);
  return res.data;
});

const CompanySlice = createSlice({
  name: 'displayedaddress',
  initialState,
  extraReducers: {
    [retrieveDisplayedAddresses.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateDisplayedAddress.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const displayedAddressIndex = state.findIndex((displayedaddress) => displayedaddress.id === id);
      state = state[displayedAddressIndex] = action.payload;
    }
  }
});

const { reducer } = CompanySlice;
export default reducer;
