import { useLocation } from 'react-router-dom';

import { useEffect, useMemo, useState } from 'react';
import { matchPath } from 'react-router';

export type NavigationItem = {
  path?: string;
  name: string;
  children?: NavigationItem[];
  hideMainCard?: boolean;
};

type CrumbItem = {
  title: string;
  link?: string;
  hideMainCard?: boolean;
};
export type NavigationItemWithCrumbs = Omit<NavigationItem, 'children'> & {
  crumbs: CrumbItem[];
  children?: NavigationItemWithCrumbs[];
};

const insertCrumbs = (crumbsObjects: NavigationItem[], pathCrumbs?: CrumbItem[]): NavigationItemWithCrumbs[] => {
  const resolvedCrumbs = pathCrumbs ?? [];
  return crumbsObjects.map((crumbsObject) => {
    const generatedCrumbs = [
      ...resolvedCrumbs,
      { title: crumbsObject.name, link: crumbsObject.path, hideMainCard: crumbsObject.hideMainCard }
    ];
    const children = crumbsObject.children ? insertCrumbs(crumbsObject.children, generatedCrumbs) : undefined;
    return {
      ...crumbsObject,
      children,
      crumbs: generatedCrumbs
    };
  });
};

/**
 * Maps objects using given property
 */
const flatMapper = <T extends { children?: Array<T> }>(obj: T[]): T[] => {
  return obj.reduce((acc, v) => {
    if (v.children) return [...acc, v, ...flatMapper(v.children)];
    return [...acc, v];
  }, [] as T[]);
};

export const useGetCrumbs = (navigation: NavigationItem[]) => {
  const location = useLocation();
  const path = location.pathname;

  const [main, setMain] = useState<CrumbItem[]>([]);
  const [item, setItem] = useState<null | string>(null);
  const [hideMainCard, setHideMainCard] = useState<boolean | undefined>();

  const crumbs = useMemo(() => {
    return flatMapper(insertCrumbs(navigation));
  }, [navigation]);
  useEffect(() => {
    const currentRoute = crumbs.find((route) => route.path && matchPath(route.path, path));
    if (!currentRoute) return;
    setMain(currentRoute.crumbs);
    setItem(currentRoute.name);
    setHideMainCard(currentRoute.hideMainCard);
  }, [path, crumbs]);
  return { main, item, hideMainCard };
};
