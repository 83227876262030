import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractQualitySpecService from '../../services/contractqualityspec.service';

const initialState = [];

export const retrieveContracQualitySpecs = createAsyncThunk('contractqualityspecs/retrieve', async () => {
  const res = await ContractQualitySpecService.getAll();
  return res.data;
});

export const deleteContractQualitySpec = createAsyncThunk('contractqualityspec/delete', async (id) => {
  const response = await ContractQualitySpecService.deleteContractQualitySpec(id);
  return response.data;
});

const ContractQualitySpecSlice = createSlice({
  name: 'contractqualityspec',
  initialState,
  extraReducers: {
    [retrieveContracQualitySpecs.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractQualitySpec.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractquality) => contractquality.id !== id);
    }
  }
});

const { reducer } = ContractQualitySpecSlice;
export default reducer;
