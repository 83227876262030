// third-party

// assets

import { FileTextOutlined, DatabaseOutlined, SolutionOutlined, FileSearchOutlined, DollarOutlined } from '@ant-design/icons';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
  type: 'group',

  children: [
    {
      id: 'contracts',
      'data-testid': 'menu-admin-contracts',
      title: 'Contracts',
      type: 'item',
      url: '/contracts',
      icon: FileTextOutlined,
      isAdminOnly: true
    },
    {
      id: 'bookings',
      title: 'Bookings',
      type: 'item',
      url: '/bookings',
      icon: FileTextOutlined,
      isAdminOnly: true
    },
    {
      id: 'shipments',
      'data-testid': 'menu-admin-shipments',
      title: 'Shipments',
      type: 'item',
      url: '/shipments',
      icon: DirectionsBoatOutlinedIcon,
      isAdminOnly: true
    },
    {
      id: 'futures',
      title: 'Futures orders',
      type: 'item',
      url: '/futures-orders',
      icon: DollarOutlined,
      isAdminOnly: true
    },
    {
      id: 'TransportSchedule',
      title: 'Transport schedules',
      type: 'item',
      url: '/search-schedules',
      icon: FileSearchOutlined,
      isAdminOnly: true
    },
    {
      id: 'masterData',
      title: 'Master data',
      type: 'collapse',
      icon: DatabaseOutlined,
      isAdminOnly: true,
      'data-testid': 'menu-admin-master-data',
      children: [
        {
          id: 'companies',
          title: 'Companies',
          type: 'item',
          url: '/master-data/companies',
          'data-testid': 'menu-admin-master-data-companies'
        },
        {
          id: 'products',
          title: 'Products',
          type: 'item',
          url: '/master-data/products'
        }
      ]
    },
    {
      id: 'documentation',
      title: 'Documentation',
      type: 'item',
      url: 'https://docs.myfrey.io/',
      icon: SolutionOutlined,
      isAdminOnly: true,
      target: true
    }
  ]
};

export default other;
