import type { CreateContractCost, UpdateContractCost, RemoveContractCost, ContractCost } from '@myfrey/contract-cost-types';

import http from '../utils/http-common';
import { protectedResources } from '../config';
import { getToken } from './utils';

const getContractCosts = async (id: number) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get<ContractCost[]>(`/contractsnew/${id}/costs`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const createContractCosts = async (id: number, data: CreateContractCost[]) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.post<ContractCost[]>(`/contractsnew/${id}/costs`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const updateContractCosts = async (id: number, data: UpdateContractCost[]) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.patch<ContractCost[]>(`/contractsnew/${id}/costs`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const removeContractCost = async (id: number, data: RemoveContractCost) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.delete<ContractCost>(`/contractsnew/${id}/costs`, {
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const saveContractCosts = async (id: number, data: CreateContractCost[]) => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.put<ContractCost[]>(`/contractsnew/${id}/costs`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const ContractCostsService = { getContractCosts, createContractCosts, updateContractCosts, removeContractCost, saveContractCosts };

export default ContractCostsService;
