// ==============================|| OVERRIDES - FORM HELPER TEXT ||============================== //
export default function FormHelperText() {
    return {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: 4,
                    marginLeft: 0
                }
            }
        }
    };
}
