import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import QualityTypeService from '../../services/qualitytype.service';

const initialState = [];

export const retrieveQualityTypes = createAsyncThunk('qualitytypes/retrieve', async () => {
  const res = await QualityTypeService.getAll();
  return res.data;
});

const qualitytypeypeSlice = createSlice({
  name: 'qualitytype',
  initialState,
  extraReducers: {
    [retrieveQualityTypes.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = qualitytypeypeSlice;
export default reducer;
