import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CountryService from '../../services/country.service';

const initialState = [];

export const retrieveCountries = createAsyncThunk('countries/retrieve', async () => {
  const res = await CountryService.getAll();
  return res.data;
});

const countriestSlice = createSlice({
  name: 'country',
  initialState,
  extraReducers: {
    [retrieveCountries.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = countriestSlice;
export default reducer;
