import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FuturesExchangeService from '../../services/futuresexchange.service';

const initialState = [];

export const retrieveFuturesExchanges = createAsyncThunk('futuresexchanges/retrieve', async () => {
  const res = await FuturesExchangeService.getAll();
  return res.data;
});

const FuturesExchangeSlice = createSlice({
  name: 'futuresexchange',
  initialState,
  extraReducers: {
    [retrieveFuturesExchanges.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = FuturesExchangeSlice;
export default reducer;
