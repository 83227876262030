import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CompanyService from '../../services/company.service';

const initialState = [];

export const retrieveCompanies = createAsyncThunk('companies/retrieve', async () => {
  const res = await CompanyService.getAll();
  return res.data;
});

export const updateCompany = createAsyncThunk('company/update', async (payload) => {
  const { id, data } = payload;
  const res = await CompanyService.update(id, data);
  return res.data;
});

export const deleteCompany = createAsyncThunk('company/delete', async (id) => {
  const response = await CompanyService.deleteCompany(id);
  return response.data;
});

const CompanySlice = createSlice({
  name: 'company',
  initialState,
  extraReducers: {
    [retrieveCompanies.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateCompany.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const companyIndex = state.findIndex((company) => company.id === id);
      state = state[companyIndex] = action.payload;
    },
    [deleteCompany.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((company) => company.id !== id);
    }
  }
});

const { reducer } = CompanySlice;
export default reducer;
