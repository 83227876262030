import { devCycleSDKKey } from '../config';
import { authProvider } from '../contexts/AuthContext/AuthContext';
import { Account } from '../contexts/AuthContext/AuthProvider';
import { initializeDevCycle } from '@devcycle/devcycle-js-sdk';

export const accountInfoToDVC = (account: { homeAccountId?: string; username?: string; name?: string }) => {
  return { user_id: account.homeAccountId, email: account?.username, name: account.name };
};

export const getToken = async (scopes: string[]) => {
  const account = authProvider.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const dvcClient = initializeDevCycle(devCycleSDKKey, accountInfoToDVC(account));
  await dvcClient.onClientInitialized();

  const scopeWithDefaults = !scopes || scopes.length === 0 ? ['urn:api:frey-app-api/.default'] : scopes;
  const response = await authProvider.acquireTokenSilent({
    account: account as Account,
    scopes: scopeWithDefaults
  });
  return response.accessToken;
};

//Usage only outside of React
export const getFeatureFlagValue = async (FFname: string, FFDefaultValue: any) => {
  const account = authProvider.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const dvcClient = initializeDevCycle(devCycleSDKKey, accountInfoToDVC(account));
  await dvcClient.onClientInitialized();
  const FFValue = dvcClient.variableValue(FFname, FFDefaultValue);
  return FFValue;
};
