import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractPackingOptionService from '../../services/contractpackingoption.service';

const initialState = [];

export const retrieveContractPackingOptions = createAsyncThunk('contractpackingoptions/retrieve', async () => {
  const res = await ContractPackingOptionService.getAll();
  return res.data;
});

export const deleteContractPackingOption = createAsyncThunk('contractpackingoption/delete', async (id, { rejectWithValue }) => {
  try {
    const response = await ContractPackingOptionService.deleteContractPackingOption(id);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const ContractPackingOptionSlice = createSlice({
  name: 'contractpackingoption',
  initialState,
  extraReducers: {
    [retrieveContractPackingOptions.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractPackingOption.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractpack) => contractpack.id !== id);
    }
  }
});

const { reducer } = ContractPackingOptionSlice;
export default reducer;
