import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractLocationOptionService from '../../services/contractlocationoption';

const initialState = [];

export const retrieveContractLocationOptions = createAsyncThunk('contractlocationoptions/retrieve', async () => {
  const res = await ContractLocationOptionService.getAll();
  return res.data;
});

export const deleteContractLocationOption = createAsyncThunk('contractdocument/delete', async (id) => {
  const response = await ContractLocationOptionService.deleteContractLocationOption(id);
  return response.data;
});

const ContractLocationOptionSlice = createSlice({
  name: 'contractlocationoption',
  initialState,
  extraReducers: {
    [retrieveContractLocationOptions.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractLocationOption.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractlocation) => contractlocation.id !== id);
    }
  }
});

const { reducer } = ContractLocationOptionSlice;
export default reducer;
