import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import QualitySpecService from '../../services/qualityspec.service';

const initialState = [];

export const retrieveQualitySpecs = createAsyncThunk('qualityspecs/retrieve', async () => {
  const res = await QualitySpecService.getAll();
  return res.data;
});

export const updateQualitySpec = createAsyncThunk('qualityspecs/update', async (payload) => {
  const { id, data } = payload;
  const res = await QualitySpecService.update(id, data);
  return res.data;
});

export const deleteQualitySpec = createAsyncThunk('qualityspecs/delete', async (id) => {
  const response = await QualitySpecService.deleteQualitySpec(id);
  return response.data;
});

const qualitySpectSlice = createSlice({
  name: 'qualityspec',
  initialState,
  extraReducers: {
    [retrieveQualitySpecs.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateQualitySpec.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const qualityspecIndex = state.findIndex((qualityspec) => qualityspec.id === id);
      state = state[qualityspecIndex] = action.payload;
    },
    [deleteQualitySpec.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((qualityspec) => qualityspec.id !== id);
    }
  }
});

const { reducer } = qualitySpectSlice;
export default reducer;
