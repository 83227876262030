import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractShipmentService from '../../services/contractshipment.service';

const initialState = [];

export const retrieveContractShipments = createAsyncThunk('contractshipments/retrieve', async () => {
  const res = await ContractShipmentService.getAll();
  return res.data;
});

export const deleteContractShipment = createAsyncThunk('contractshipment/delete', async (id) => {
  const response = await ContractShipmentService.deleteContractShipment(id);
  return response.data;
});

const ContractShipmentSlice = createSlice({
  name: 'contractshipment',
  initialState,
  extraReducers: {
    [retrieveContractShipments.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractShipment.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractshipment) => contractshipment.id !== id);
    }
  }
});

const { reducer } = ContractShipmentSlice;
export default reducer;
