import { JobBase } from './JobBase';
import { CreateNotificationDefinition } from '../NotificationsContext';
import { pollDocumentGenerationStatus } from '../../pages/Shipments/shipment-details/TabPanels/ShipmentFileManagement/pollDocumentGenerationStatus';
import ShipmentService from '../../services/shipment.service';

type ShipmentDocumentStatusPollJobJSON = {
  id: string;
  kind: 'ShipmentDocumentStatusPollJob';
  documentType: string;
  shipmentId: string;
  jobId: string;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProfressNotificationId?: string;
};
type ShipmentDocumentStatusPollJobStartArgs = {
  addNotification: (notification: CreateNotificationDefinition) => string;
  removeNotification: (notificationId: string) => void;
};
class ShipmentDocumentStatusPollJob extends JobBase<ShipmentDocumentStatusPollJobJSON, ShipmentDocumentStatusPollJobStartArgs> {
  kind = 'ShipmentDocumentStatusPollJob';
  status = 'pending' as const;

  documentType: string;
  shipmentId: string;
  jobId: string;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProfressNotificationId?: string;

  constructor(id: string, saveJobs: () => void, json: ShipmentDocumentStatusPollJobJSON) {
    super(id, saveJobs);

    this.documentType = json.documentType;
    this.shipmentId = json.shipmentId;
    this.jobId = json.jobId;
    this.onTimeout = json.onTimeout;
    this.onOk = json.onOk;
    this.onError = json.onError;
    this.inProfressNotificationId = json.inProfressNotificationId;
  }

  start = ({ addNotification, removeNotification }: ShipmentDocumentStatusPollJobStartArgs) => {
    this.setStatus('running');
    const { shipmentId, jobId, documentType, onTimeout, onOk, onError } = this;
    pollDocumentGenerationStatus(() => ShipmentService.getGenerateShipmentDocumentStatus(shipmentId, jobId), 0, {
      onTimeout: () => {
        if (this.inProfressNotificationId) return;

        onTimeout?.();
        this.inProfressNotificationId = addNotification({
          message: `${documentType} generation in progress...`,
          type: 'info',
          refUrl: {
            label: "Got to shipment's details",
            url: `/shipments/${shipmentId}/details`
          }
        });
        this.saveJobs();
      },
      onOk: () => {
        onOk?.();
        addNotification({
          message: `${documentType} was generated successfully!`,
          refUrl: {
            label: "Got to shipment's details",
            url: `/shipments/${shipmentId}/details`
          },
          type: 'success'
        });
        if (this.inProfressNotificationId) removeNotification(this.inProfressNotificationId);
        this.setStatus('finished');
      },
      onError: () => {
        onError?.();
        addNotification({
          message: `${documentType} failed to generate`,
          refUrl: {
            label: "Got to shipment's details",
            url: `/shipments/${shipmentId}/details`
          },
          type: 'error'
        });
        if (this.inProfressNotificationId) removeNotification(this.inProfressNotificationId);
        this.setStatus('error');
      }
    });
  };

  cancel = () => {
    this.setStatus('cancelled');
  };

  toJSON = () => {
    return {
      id: this.id,
      kind: this.kind as 'ShipmentDocumentStatusPollJob',
      documentType: this.documentType,
      shipmentId: this.shipmentId,
      jobId: this.jobId,
      inProfressNotificationId: this.inProfressNotificationId
    };
  };
}

export { ShipmentDocumentStatusPollJob };
export type { ShipmentDocumentStatusPollJobJSON };
