// ==============================|| OVERRIDES - TAB ||============================== //
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var main = colors.main;
    return {
        border: "2px solid ".concat(main)
    };
}
export default function Slider(theme) {
    return {
        MuiSlider: {
            styleOverrides: {
                track: {
                    height: '1px'
                },
                thumb: {
                    width: 14,
                    height: 14,
                    border: "2px solid ".concat(theme.palette.primary.main),
                    backgroundColor: theme.palette.background.paper,
                    '&.MuiSlider-thumbColorPrimary': getColorStyle({ color: 'primary', theme: theme }),
                    '&.MuiSlider-thumbColorSecondary': getColorStyle({ color: 'secondary', theme: theme }),
                    '&.MuiSlider-thumbColorSuccess': getColorStyle({ color: 'success', theme: theme }),
                    '&.MuiSlider-thumbColorWarning': getColorStyle({ color: 'warning', theme: theme }),
                    '&.MuiSlider-thumbColorInfo': getColorStyle({ color: 'info', theme: theme }),
                    '&.MuiSlider-thumbColorError': getColorStyle({ color: 'error', theme: theme })
                },
                mark: {
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                    border: "1px solid ".concat(theme.palette.secondary.light),
                    backgroundColor: theme.palette.background.paper,
                    '&.MuiSlider-markActive': {
                        opacity: 1,
                        borderColor: 'inherit',
                        borderWidth: 2
                    }
                },
                rail: {
                    color: theme.palette.secondary.light
                },
                root: {
                    '&.Mui-disabled': {
                        '.MuiSlider-rail': {
                            opacity: 0.25
                        },
                        '.MuiSlider-track': {
                            color: theme.palette.secondary.lighter
                        },
                        '.MuiSlider-thumb': {
                            border: "2px solid ".concat(theme.palette.secondary.lighter)
                        }
                    }
                },
                valueLabel: {
                    backgroundColor: theme.palette.grey[600]
                    // color: theme.palette.grey[0]
                }
            }
        }
    };
}
