import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { ReactElement } from 'react';

interface DialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  children: ReactElement;
  testId?: string;
}

export default function DialogBox({ open, setOpen, children, testId }: DialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} data-testid={`${testId}-dialog`}>
        <DialogContent>{React.cloneElement(children, { setOpen })}</DialogContent>
      </Dialog>
    </div>
  );
}
