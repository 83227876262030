import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EquipmentService from '../../services/equipment.service';

const initialState = [];

export const retrieveEquipments = createAsyncThunk('equipments/retrieve', async () => {
  const res = await EquipmentService.getAll();
  return res.data;
});

const EquipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  extraReducers: {
    [retrieveEquipments.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = EquipmentSlice;
export default reducer;
