import { createContext, useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { v4 as uuid } from 'uuid';

export type DialogOptions = 'split-shipment-details';

export type NotificationDefinition = {
  id: string;
  read: boolean;
  timestamp: number;

  message: string;
  refUrl?: { label: string; url: string };
  detailsInDialog?: {
    label: string;
    dialog: DialogOptions;
    shipmentId: string;
    jobId: string;
    shipmentReference: string;
    bookingReference: string;
  };

  type: 'success' | 'error' | 'warning' | 'info';
};

export type CreateNotificationDefinition = Omit<NotificationDefinition, 'read' | 'id' | 'timestamp'>;

export type NotificationsContextType = {
  notifications: NotificationDefinition[];
  addNotification: (notification: CreateNotificationDefinition) => string;
  removeNotification: (id: string) => void;
  markNotificationAsRead: (id: string) => void;
};

const NotificationsContext = createContext<NotificationsContextType>({
  notifications: [],
  addNotification: () => '',
  removeNotification: () => {},
  markNotificationAsRead: () => {}
});

const LOCAL_STORAGE_KEY = 'notifications';

const saveNotificationsToLocalStorage = (notifications: NotificationDefinition[]) => {
  const stringifiedNotifications = JSON.stringify(notifications);
  localStorage.setItem(LOCAL_STORAGE_KEY, stringifiedNotifications);
};

const loadNotificationsFromLocalStorage = (): NotificationDefinition[] => {
  const stringifiedNotifications = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!stringifiedNotifications) {
    return [];
  }
  return JSON.parse(stringifiedNotifications);
};

export const NotificationsProvider: React.ComponentType<React.PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationDefinition[]>(loadNotificationsFromLocalStorage());

  const addNotification = useCallback((notification: CreateNotificationDefinition) => {
    const id = `${LOCAL_STORAGE_KEY}_${uuid()}`;
    setNotifications((notifications) => [...notifications, { ...notification, read: false, id, timestamp: Date.now() }]);
    return id;
  }, []);

  const removeNotification = useCallback((id: string) => {
    setNotifications((notifications) => notifications.filter((notification) => notification.id !== id));
  }, []);

  const markNotificationAsRead = useCallback((id: string) => {
    setNotifications((notifications) =>
      notifications.map((notification) => (notification.id === id ? { ...notification, read: true } : notification))
    );
  }, []);

  useEffect(() => {
    saveNotificationsToLocalStorage(notifications);
  }, [notifications]);

  const contextValue = useMemo<NotificationsContextType>(
    () => ({
      notifications,
      addNotification,
      removeNotification,
      markNotificationAsRead
    }),
    [notifications, addNotification, removeNotification, markNotificationAsRead]
  );

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};
