var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// ==============================|| CHIP - COLORS ||============================== //
function getColor(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var dark = colors.dark;
    return {
        '&.Mui-focusVisible': {
            outline: "2px solid ".concat(dark),
            outlineOffset: 2
        }
    };
}
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var light = colors.light, lighter = colors.lighter, main = colors.main;
    return {
        color: main,
        backgroundColor: lighter,
        borderColor: light,
        '& .MuiChip-deleteIcon': {
            color: main,
            '&:hover': {
                color: light
            }
        }
    };
}
// ==============================|| OVERRIDES - CHIP ||============================== //
export default function Chip(theme) {
    var defaultLightChip = getColorStyle({ color: 'secondary', theme: theme });
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    '&:active': {
                        boxShadow: 'none'
                    },
                    '&.MuiChip-colorPrimary': getColor({ color: 'primary', theme: theme }),
                    '&.MuiChip-colorSecondary': getColor({ color: 'secondary', theme: theme }),
                    '&.MuiChip-colorError': getColor({ color: 'error', theme: theme }),
                    '&.MuiChip-colorInfo': getColor({ color: 'info', theme: theme }),
                    '&.MuiChip-colorSuccess': getColor({ color: 'success', theme: theme }),
                    '&.MuiChip-colorWarning': getColor({ color: 'warning', theme: theme })
                },
                sizeLarge: {
                    fontSize: '1rem',
                    height: 40
                },
                light: __assign(__assign({}, defaultLightChip), { '&.MuiChip-lightPrimary': getColorStyle({ color: 'primary', theme: theme }), '&.MuiChip-lightSecondary': getColorStyle({ color: 'secondary', theme: theme }), '&.MuiChip-lightError': getColorStyle({ color: 'error', theme: theme }), '&.MuiChip-lightInfo': getColorStyle({ color: 'info', theme: theme }), '&.MuiChip-lightSuccess': getColorStyle({ color: 'success', theme: theme }), '&.MuiChip-lightWarning': getColorStyle({ color: 'warning', theme: theme }) }),
                combined: __assign(__assign({ border: '1px solid' }, defaultLightChip), { '&.MuiChip-combinedPrimary': getColorStyle({ color: 'primary', theme: theme }), '&.MuiChip-combinedSecondary': getColorStyle({ color: 'secondary', theme: theme }), '&.MuiChip-combinedError': getColorStyle({ color: 'error', theme: theme }), '&.MuiChip-combinedInfo': getColorStyle({ color: 'info', theme: theme }), '&.MuiChip-combinedSuccess': getColorStyle({ color: 'success', theme: theme }), '&.MuiChip-combinedWarning': getColorStyle({ color: 'warning', theme: theme }) })
            }
        }
    };
}
