import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import BookingService from '../../services/booking.service.ts';

const initialState = [];

export const retrieveBookings = createAsyncThunk('bookings/retrieve', async () => {
  const res = await BookingService.getAllBookings();
  return res.data;
});

export const retrieveBooking = createAsyncThunk('booking/retrieve', async (id, { rejectWithValue }) => {
  try {
    const res = await BookingService.getBooking(id);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateBooking = createAsyncThunk('bookings/update', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await BookingService.updateBooking(id, data);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const BookingSlice = createSlice({
  name: 'booking',
  initialState,
  extraReducers: {
    [retrieveBookings.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = BookingSlice;
export default reducer;
