var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { alpha, createTheme } from '@mui/material/styles';
import { presetDarkPalettes, presetPalettes } from '@ant-design/colors';
import ThemeOption from './theme';
// ==============================|| DEFAULT THEME - PALETTE ||============================== //
var Palette = function (mode, presetColor) {
    var colors = mode === 'dark' ? presetDarkPalettes : presetPalettes;
    var greyPrimary = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000'
    ];
    var greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    var greyConstant = ['#fafafb', '#e6ebf1'];
    if (mode === 'dark') {
        greyPrimary = ['#000000', '#141414', '#1e1e1e', '#595959', '#8c8c8c', '#bfbfbf', '#d9d9d9', '#f0f0f0', '#f5f5f5', '#fafafa', '#ffffff'];
        // greyPrimary.reverse();
        greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
        greyConstant = ['#121212', '#d3d8db'];
    }
    colors.grey = __spreadArray(__spreadArray(__spreadArray([], greyPrimary, true), greyAscent, true), greyConstant, true);
    var paletteColor = ThemeOption[presetColor](colors, mode);
    return createTheme({
        palette: __assign(__assign({ mode: mode, common: {
                black: '#000',
                white: '#fff'
            } }, paletteColor), { text: {
                primary: mode === 'dark' ? alpha(paletteColor.grey[900], 0.87) : paletteColor.grey[700],
                secondary: mode === 'dark' ? alpha(paletteColor.grey[900], 0.45) : paletteColor.grey[500],
                disabled: mode === 'dark' ? alpha(paletteColor.grey[900], 0.1) : paletteColor.grey[400]
            }, action: {
                disabled: paletteColor.grey[300]
            }, divider: mode === 'dark' ? alpha(paletteColor.grey[900], 0.05) : paletteColor.grey[200], background: {
                paper: mode === 'dark' ? paletteColor.grey[100] : paletteColor.grey[0],
                default: paletteColor.grey.A50
            } })
    });
};
export default Palette;
