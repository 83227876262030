import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DocumentIssuerService from '../../services/documentissuer.service';

const initialState = [];

export const retrieveDocumentIssuers = createAsyncThunk('documentissuers/retrieve', async () => {
  const res = await DocumentIssuerService.getAll();
  return res.data;
});

const DocumentIssuerSlice = createSlice({
  name: 'document issuer',
  initialState,
  extraReducers: {
    [retrieveDocumentIssuers.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = DocumentIssuerSlice;
export default reducer;
