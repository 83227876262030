import http from '../utils/http-common';
import { protectedResources } from '../config';
import { getToken } from './utils';

interface RouteScheduleParams {
  porID: string;
  fndID: string;
  departureFrom: string;
  departureTo: string;
  arrivalFrom: string;
  arrivalTo: string;
  carrier: string;
}

const getRouteSchedules = async (params: RouteScheduleParams) => {
  const accessToken = await getToken(protectedResources.transportscheduleapi.scopes.read);
  return http.get('/transport-schedule', {
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const RouteScheduleService = {
  getRouteSchedules
};

export default RouteScheduleService;
