import promiseRetry from 'promise-retry';
import { JobStatus as DocumentJobStatus } from '@myfrey/shipments-types';

export const PENDING_POLL_RESULT_TIMEOUT = 15 * 60 * 1000; // 15 min

export function pollDocumentGenerationStatus(
  getJobStatus: () => Promise<{ data: { jobStatus: 'IN_PROGRESS' | 'FAILED' | 'SUCCESS' } }>,
  pendingTimeout: number,
  { onTimeout, onOk, onError }: { onTimeout?: () => void; onOk?: () => void; onError?: () => void } = {}
): void {
  const timeout = setTimeout(() => {
    onTimeout?.();
  }, pendingTimeout);

  promiseRetry(
    async (retry) => {
      const result = await getJobStatus();
      const generationJobStatus = result.data.jobStatus as DocumentJobStatus;
      switch (generationJobStatus) {
        case 'IN_PROGRESS':
          retry('pending');
          break;
        case 'FAILED':
          onError?.();
          clearTimeout(timeout);
          break;
        case 'SUCCESS':
          onOk?.();
          clearTimeout(timeout);
          break;
      }
    },
    { factor: 2, minTimeout: 800 }
  );
}
