import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FuturesContractService from '../../services/futurescontract.services';

const initialState = [];

export const retrieveFuturesContracts = createAsyncThunk('futurescontracts/retrieve', async () => {
  const res = await FuturesContractService.getAll();
  return res.data;
});

const FuturesContractSlice = createSlice({
  name: 'futurescontract',
  initialState,
  extraReducers: {
    [retrieveFuturesContracts.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = FuturesContractSlice;
export default reducer;
