import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractCarrierService from '../../services/contractcarrier.service';

const initialState = [];

export const retrieveContractCarriers = createAsyncThunk('contractcarriers/retrieve', async () => {
  const res = await ContractCarrierService.getAll();
  return res.data;
});

export const deleteContractCarrier = createAsyncThunk('contractcarrier/delete', async (id) => {
  const response = await ContractCarrierService.deleteContractCarrier(id);
  return response.data;
});

const ContractCarrierSlice = createSlice({
  name: 'contractcarrier',
  initialState,
  extraReducers: {
    [retrieveContractCarriers.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractCarrier.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractcarrier) => contractcarrier.id !== id);
    }
  }
});

const { reducer } = ContractCarrierSlice;
export default reducer;
