import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DeliveryTermService from '../../services/deliveryterm.service';

const initialState = [];

export const retrieveDeliveryTerms = createAsyncThunk('deliveryterms/retrieve', async () => {
  const res = await DeliveryTermService.getAll();
  return res.data;
});

const DeliveryTermSlice = createSlice({
  name: 'deliveryterm',
  initialState,
  extraReducers: {
    [retrieveDeliveryTerms.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = DeliveryTermSlice;
export default reducer;
