import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import containerfacilitiyService from '../../services/containerfacility.service';

const initialState = [];

export const retrieveContainerfacilities = createAsyncThunk('container-facilities/retrieve', async () => {
  const res = await containerfacilitiyService.getAll();
  return res.data;
});

const containerfacilitiySlice = createSlice({
  name: 'containerfacilitiy',
  initialState,
  extraReducers: {
    [retrieveContainerfacilities.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = containerfacilitiySlice;
export default reducer;
