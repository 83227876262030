import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PackingWeightOptionService from '../../services/packingweightoption.service';

const initialState = [];

export const retrievePackingWeightOptions = createAsyncThunk('packingweightoptions/retrieve', async () => {
  const res = await PackingWeightOptionService.getAll();
  return res.data;
});

export const deletePackingWeightOption = createAsyncThunk('packingweightoption/delete', async (id) => {
  const response = await PackingWeightOptionService.deletePackingWeightOption(id);
  return response.data;
});

const PackingWeightOptionSlice = createSlice({
  name: 'packingweightoption',
  initialState,
  extraReducers: {
    [retrievePackingWeightOptions.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deletePackingWeightOption.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((packweight) => packweight.id !== id);
    }
  }
});

const { reducer } = PackingWeightOptionSlice;
export default reducer;
