import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReferenceService from '../../services/reference.service';

const initialState = [];

export const retrieveReferences = createAsyncThunk('references/retrieve', async () => {
  const res = await ReferenceService.getAll();
  return res.data;
});

const referenceSlice = createSlice({
  name: 'reference',
  initialState,
  extraReducers: {
    [retrieveReferences.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = referenceSlice;
export default reducer;
