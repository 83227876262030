import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProductCategoryService from '../../services/productcategory.service';

const initialState = [];

export const retrieveProductCategories = createAsyncThunk('productcategory/retrieve', async () => {
  const res = await ProductCategoryService.getAll();
  return res.data;
});

const productcategorySlice = createSlice({
  name: 'productcategory',
  initialState,
  extraReducers: {
    [retrieveProductCategories.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = productcategorySlice;
export default reducer;
