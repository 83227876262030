import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TraderService from '../../services/trader.service';

const initialState = [];

export const retrieveTraders = createAsyncThunk('traders/retrieve', async () => {
  const res = await TraderService.getAll();
  return res.data;
});

export const deleteTrader = createAsyncThunk('traders/delete', async (id) => {
  const response = await TraderService.deleteTrader(id);
  return response.data;
});

const traderSlice = createSlice({
  name: 'trader',
  initialState,
  extraReducers: {
    [retrieveTraders.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteTrader.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((trader) => trader.id !== id);
    }
  }
});

const { reducer } = traderSlice;
export default reducer;
