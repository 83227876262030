import axios from 'axios';
import { Client } from 'typesense';

import { apiBaseUrl } from '../config';

const instance = axios.create({
  baseURL: `${apiBaseUrl}/api`,
  headers: {
    'Content-type': 'application/json'
  }
});

instance.interceptors.request.use((axiosConfig) => {
  if (!axiosConfig.headers) axiosConfig.headers = {};
  axiosConfig.headers['Expect-Enhanced-Data'] = true;
  axiosConfig.headers['Access-Control-Allow-Headers'] = 'Expect-Enhanced-Data';
  return axiosConfig;
});

export default instance;
