import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractProductService from '../../services/contractproduct.service';

const initialState = [];

export const retrieveContractProducts = createAsyncThunk('contractproducts/retrieve', async () => {
  const res = await ContractProductService.getAll();
  return res.data;
});

export const deleteContractProduct = createAsyncThunk('contractproduct/delete', async (id) => {
  const response = await ContractProductService.deleteContractProduct(id);
  return response.data;
});

const ContractProductSlice = createSlice({
  name: 'contractproduct',
  initialState,
  extraReducers: {
    [retrieveContractProducts.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractProduct.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractproduct) => contractproduct.id !== id);
    }
  }
});

const { reducer } = ContractProductSlice;
export default reducer;
