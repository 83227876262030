import http from '../utils/http-common';

import { getToken } from './utils';

const getAll = async () => {
  const accessToken = await getToken();

  return http.get('/quality-operators', {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const get = async (id) => {
  const accessToken = await getToken();

  return http.get(`/quality-operators/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const QualityOperatorService = {
  getAll,
  get
};

export default QualityOperatorService;
