var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// ==============================|| OVERRIDES - TABLE ROW ||============================== //
export default function TableBody(theme) {
    var hoverStyle = {
        '&:hover': {
            backgroundColor: theme.palette.secondary.lighter
        }
    };
    return {
        MuiTableBody: {
            styleOverrides: {
                root: {
                    '&.striped .MuiTableRow-root': __assign({ '&:nth-of-type(even)': {
                            backgroundColor: theme.palette.grey[50]
                        } }, hoverStyle),
                    '& .MuiTableRow-root': __assign({}, hoverStyle)
                }
            }
        }
    };
}
