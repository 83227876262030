import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BrokerService from '../../services/broker.service';

const initialState = [];

export const retrieveBrokers = createAsyncThunk('brokers/retrieve', async () => {
  const res = await BrokerService.getAll();
  return res.data;
});

const BrokerSlice = createSlice({
  name: 'broker',
  initialState,
  extraReducers: {
    [retrieveBrokers.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = BrokerSlice;
export default reducer;
