import { ContractCost, CreateContractCost, RemoveContractCost, UpdateContractCost } from '@myfrey/contract-cost-types';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractCostService from '../../services/contractCost.service';

const initialState: ContractCost[] = [];

export const retreiveContractCosts = createAsyncThunk('contractCost/retrieve', async (id: number) => {
  const res = await ContractCostService.getContractCosts(id);
  return res.data;
});

export const createContractCosts = createAsyncThunk(
  'contractCost/create',
  async ({ id, data }: { id: number; data: CreateContractCost[] }) => {
    const res = await ContractCostService.createContractCosts(id, data);
    return res.data;
  }
);

export const updateContractCosts = createAsyncThunk(
  'contractCost/update',
  async ({ id, data }: { id: number; data: UpdateContractCost[] }) => {
    const res = await ContractCostService.updateContractCosts(id, data);
    return res.data;
  }
);

export const removeContractCost = createAsyncThunk(
  'contractCost/remove',
  async ({ id, data }: { id: number; data: RemoveContractCost }) => {
    const res = await ContractCostService.removeContractCost(id, data);
    return res.data;
  }
);

const CarrierSlice = createSlice({
  name: 'contractCost',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retreiveContractCosts.fulfilled, (state, action) => {
      return [...action.payload];
    });
    builder.addCase(createContractCosts.fulfilled, (state, action) => {
      state = [...state, ...action.payload];
    });
    builder.addCase(updateContractCosts.fulfilled, (state, action) => {
      action.payload.forEach((updatedContractCost) => {
        const contractCostIndex = state.findIndex((contractCost) => contractCost.id === updatedContractCost.id);
        state[contractCostIndex] = updatedContractCost;
      });
    });
    builder.addCase(removeContractCost.fulfilled, (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractCost) => contractCost.id !== id);
    });
  }
});

const { reducer } = CarrierSlice;
export default reducer;
