import { crumbsConfigItem, crumbsObject, crumbsObjectWithCrumbs, pathCrumb } from './types';

export const crumbsConfigObject: crumbsObject[] = [
  {
    name: 'Home',
    path: '/',

    children: [
      { name: 'Home', path: 'home' },
      {
        name: 'Insights',
        children: [
          {
            name: 'Shipments',
            path: 'insights/shipments'
          }
        ]
      },

      {
        name: 'Contracts',
        path: 'contracts',
        children: [
          {
            name: 'Add Contract',
            path: 'contracts/add'
          },
          {
            name: 'Add Sales Contract',
            path: 'contracts/sale/add'
          },
          {
            name: 'Add Purchase Contract',
            path: 'contracts/purchase/add'
          },
          {
            name: 'Edit Contract',
            path: 'contracts/:id/edit',
            hideMainCard: true
          },
          {
            name: 'Details',
            path: 'contracts/:type/:id/details',
            hideMainCard: true
          }
        ]
      },
      {
        name: 'Master data',
        children: [
          {
            name: 'Companies',
            path: 'master-data/companies'
          },
          {
            name: 'Add Company',
            path: 'master-data/companies/add'
          },
          {
            name: 'Edit Company',
            path: 'master-data/companies/:id/edit'
          },

          {
            name: 'Products',
            path: 'master-data/products'
          },
          {
            name: 'Add Product',
            path: 'master-data/products/add'
          },
          {
            name: 'Edit Product',
            path: 'master-data/products/:id/edit'
          },
          {
            name: 'Document Types',
            path: 'master-data/document-types'
          },
          {
            name: 'Add Document Type',
            path: 'master-data/document-types/add'
          },
          {
            name: 'Edit Document Type',
            path: 'master-data/document-types/:id/edit'
          }
        ]
      },
      {
        name: 'Bookings',
        path: 'bookings',
        children: [
          {
            name: 'Add Booking',
            path: 'bookings/add'
          },
          {
            name: 'Edit Booking',
            path: 'bookings/:id/edit'
          },
          {
            name: 'Details',
            path: 'bookings/:id/details',
            hideMainCard: true
          },
          {
            name: 'Containers',
            path: 'bookings/:id/containers',
            hideMainCard: true
          }
        ]
      },
      {
        name: 'Futures orders',
        path: 'futures-orders',

        children: [
          {
            name: 'Overview',
            path: 'futures-orders/overview',
          }
        ]
      },
      {
        name: 'Transport schedules',
        path: 'search-schedules',
        children: [
          {
            name: 'Overview',
            path: 'search-schedules/overview'
          }
        ]
      },

      {
        name: 'Shipments',
        path: 'shipments',
        children: [
          {
            name: 'Add Shipment',
            path: 'shipments/add'
          },
          {
            name: 'Edit Shipment',
            path: 'shipments/:id/edit',
            hideMainCard: true
          },
          {
            name: 'Details',
            path: 'shipments/:id/details',
            hideMainCard: true
          },
          {
            name: 'Costs',
            path: 'shipments/:id/costs',
            hideMainCard: true
          },
          {
            name: 'Containers',
            path: 'shipments/:id/containers',
            hideMainCard: true
          },
          {
            name: 'Booking',
            path: 'shipments/:id/booking',
            hideMainCard: true
          },
          {
            name: 'Documents',
            path: 'shipments/:id/documents',
            hideMainCard: true
          },
          {
            name: 'Document Instructions',
            path: 'shipments/:id/document-instructions',
            hideMainCard: true
          },
          {
            name: 'Loading Instructions',
            path: 'shipments/:id/loading-instructions',
            hideMainCard: true
          }
        ]
      }
    ]
  }
];
const insertCrumbs = (crumbsObjects: crumbsObject[], pathCrumbs?: pathCrumb[]): crumbsObjectWithCrumbs[] => {
  const resolvedCrumbs = pathCrumbs ?? [];
  return crumbsObjects.map((crumbsObject) => {
    const generatedCrumbs = [
      ...resolvedCrumbs,
      { title: crumbsObject.name, link: crumbsObject.path, hideMainCard: crumbsObject.hideMainCard }
    ];
    const children = crumbsObject.children ? insertCrumbs(crumbsObject.children, generatedCrumbs) : undefined;
    return {
      ...crumbsObject,
      children,
      pathCrumbs: generatedCrumbs
    };
  });
};

const flatMapper = (obj: crumbsObjectWithCrumbs[]): crumbsConfigItem[] => {
  return obj.reduce((acc, v) => {
    if (v.children) return [...acc, v, ...flatMapper(v.children)];
    return [...acc, v];
  }, [] as crumbsConfigItem[]);
};
export const crumbsConfig = flatMapper(insertCrumbs(crumbsConfigObject));
