import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractDocumentService from '../../services/contractdocument.service';

const initialState = [];

export const retrieveContractDocuments = createAsyncThunk('contractdocuments/retrieve', async () => {
  const res = await ContractDocumentService.getAll();
  return res.data;
});

export const updateContractDocument = createAsyncThunk('contractdocument/update', async (payload) => {
  const { id, data } = payload;
  const res = await ContractDocumentService.update(id, data);
  return res.data;
});

export const deleteContractDocument = createAsyncThunk('contractdocument/delete', async (id) => {
  const response = await ContractDocumentService.deleteContractDocument(id);
  return response.data;
});

const ContractDocumentSlice = createSlice({
  name: 'contractdocument',
  initialState,
  extraReducers: {
    [retrieveContractDocuments.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateContractDocument.fulfilled]: (state, action) => {
      const { id } = action.payload;
      const contractDocumentIndex = state.findIndex((contractdocument) => contractdocument.id === id);
      state = state[contractDocumentIndex] = action.payload;
    },
    [deleteContractDocument.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractdocument) => contractdocument.id !== id);
    }
  }
});

const { reducer } = ContractDocumentSlice;
export default reducer;
