import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import QualityCategoryService from '../../services/qualitycategory.service';

const initialState = [];

export const retrieveQualityCategories = createAsyncThunk('qualitycategories/retrieve', async () => {
  const res = await QualityCategoryService.getAll();
  return res.data;
});

const qualitycategorytSlice = createSlice({
  name: 'qualitycategory',
  initialState,
  extraReducers: {
    [retrieveQualityCategories.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = qualitycategorytSlice;
export default reducer;
