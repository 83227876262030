// material-ui
import { useTheme } from '@mui/material/styles';

// import logoIconDark from 'assets/images/logo.png';
// import logoIcon from 'assets/images/logo.png';

const LogoIcon = () => {
  const theme = useTheme();

  return (
    /* 
     <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Frey" width="100" />
     */

    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="35"
      viewBox="0 0 750.000000 648.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,648.000000) scale(0.100000,-0.100000)" fill={theme.palette.primary.main} stroke="none">
        <path
          d="M2990 6470 c-445 -39 -842 -152 -1225 -349 -194 -100 -309 -172 -475
-297 -706 -534 -1155 -1315 -1267 -2204 -23 -185 -23 -575 0 -760 112 -889
561 -1670 1267 -2204 746 -564 1686 -775 2610 -586 1259 258 2260 1262 2514
2523 49 242 60 364 60 647 0 341 -31 578 -114 875 -353 1257 -1427 2177 -2730
2340 -153 19 -498 27 -640 15z m700 -209 c1373 -213 2424 -1302 2585 -2676 9
-78 15 -216 15 -345 0 -243 -15 -403 -60 -620 -87 -422 -271 -836 -522 -1176
-498 -672 -1197 -1096 -2023 -1226 -122 -20 -179 -22 -445 -22 -266 0 -323 3
-447 22 -534 84 -1010 290 -1443 626 -135 104 -402 371 -506 506 -336 432
-542 908 -626 1443 -20 124 -22 180 -22 447 0 267 2 323 22 447 84 535 290
1011 626 1443 104 135 371 402 506 506 482 374 1015 587 1625 648 22 2 162 3
310 1 215 -2 298 -7 405 -24z"
        />
        <path
          d="M2927 4874 c-207 -37 -419 -165 -541 -325 -38 -50 -107 -188 -277
-550 -124 -266 -223 -487 -219 -492 3 -4 44 16 91 44 240 145 507 245 789 295
81 15 203 18 850 24 820 7 782 4 931 65 143 58 315 195 421 335 101 133 179
334 193 497 7 74 6 82 -14 102 l-21 21 -1062 -1 c-866 -1 -1077 -3 -1141 -15z"
        />
        <path
          d="M2817 3704 c-270 -42 -573 -156 -794 -299 -140 -91 -274 -232 -361
-381 -29 -50 -256 -533 -286 -609 -44 -114 -58 -265 -32 -360 15 -57 46 -125
57 -125 4 0 9 60 11 133 5 153 25 218 103 339 168 258 445 409 870 472 55 9
233 29 395 46 369 39 472 54 585 91 232 75 454 254 589 478 66 109 88 171 73
202 l-13 24 -554 2 c-427 1 -575 -2 -643 -13z"
        />
        <path
          d="M2555 2744 c-551 -97 -641 -131 -821 -309 -74 -73 -98 -106 -132
-175 -57 -116 -71 -205 -49 -314 44 -213 181 -341 380 -353 135 -9 239 30 345
130 71 68 121 150 197 326 36 82 112 250 171 373 141 295 156 330 150 345 -7
17 -19 16 -241 -23z"
        />
        <path
          d="M9109 5076 c-105 -28 -200 -112 -241 -215 l-23 -56 0 -1516 c0 -1270
2 -1519 14 -1532 12 -15 50 -17 367 -17 l353 0 15 22 c14 20 16 95 16 615 l0
593 601 0 c395 0 607 4 620 10 18 10 19 25 19 353 0 254 -3 346 -12 355 -9 9
-160 12 -620 12 l-608 0 0 325 0 325 621 2 c601 3 621 4 635 22 11 16 14 80
14 348 0 315 -1 329 -20 348 -20 20 -33 20 -862 19 -635 0 -854 -3 -889 -13z"
        />
        <path
          d="M14105 4189 c-112 -11 -271 -50 -367 -90 -158 -65 -272 -144 -404
-281 -171 -177 -266 -361 -321 -618 -25 -117 -25 -408 0 -532 76 -378 306
-686 637 -853 103 -51 243 -98 371 -121 132 -25 458 -25 584 0 287 56 504 176
678 373 50 58 58 90 30 116 -24 21 -505 297 -518 297 -6 0 -35 -19 -65 -41
-116 -88 -244 -129 -406 -129 -227 0 -403 71 -500 203 -24 32 -44 66 -44 74 0
7 11 25 25 38 l24 25 799 0 c756 0 800 1 815 18 24 26 40 193 33 332 -17 344
-128 609 -350 838 -175 181 -377 291 -615 336 -102 19 -295 27 -406 15z m360
-658 c136 -49 225 -140 281 -287 l24 -64 -515 0 c-461 0 -515 2 -515 16 0 31
68 160 108 205 67 75 156 125 267 149 73 16 283 5 350 -19z"
        />
        <path
          d="M12671 4169 c-142 -17 -317 -95 -422 -187 -57 -51 -112 -130 -144
-207 l-24 -59 -3 193 c-3 177 -5 194 -22 207 -16 11 -79 14 -338 14 -305 0
-319 -1 -338 -20 -20 -20 -20 -33 -20 -1178 0 -1110 1 -1160 18 -1175 17 -15
55 -17 345 -17 325 0 326 0 341 22 14 20 16 93 16 593 0 480 2 580 15 630 39
151 116 245 255 313 112 55 183 72 309 77 79 2 107 7 120 19 14 15 16 59 19
369 2 371 0 392 -43 407 -11 3 -49 3 -84 -1z"
        />
        <path
          d="M15625 4110 c-11 -12 -16 -29 -13 -47 3 -15 208 -515 456 -1109 254
-608 452 -1095 452 -1111 0 -48 -67 -169 -132 -239 -42 -44 -80 -74 -124 -95
-66 -31 -186 -59 -255 -59 -26 0 -46 -7 -59 -20 -19 -19 -20 -33 -20 -313 0
-249 2 -296 16 -315 14 -20 23 -22 111 -22 201 0 403 48 571 135 251 129 462
386 608 740 85 206 725 2042 731 2096 10 103 -45 234 -127 299 -83 65 -118 74
-312 78 l-177 4 -35 -114 c-19 -62 -119 -385 -221 -717 -102 -333 -188 -607
-190 -610 -3 -2 -9 0 -14 5 -5 5 -122 322 -261 704 -138 382 -259 703 -267
713 -15 16 -46 17 -368 17 -337 0 -353 -1 -370 -20z"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear" x1="8.62526" y1="14.0888" x2="5.56709" y2="17.1469" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.palette.primary.darker} />
          <stop offset="0.9637" stopColor={theme.palette.primary.dark} stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="26.2675" y1="14.1279" x2="28.7404" y2="16.938" gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.palette.primary.darker} />
          <stop offset="1" stopColor={theme.palette.primary.dark} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoIcon;
