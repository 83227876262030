import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ContractLogisticsProviderService from '../../services/contractlogisticsserviceprovider.service';

const initialState = [];

export const retrieveContractLogisticsProviders = createAsyncThunk('contractproviders/retrieve', async () => {
  const res = await ContractLogisticsProviderService.getAll();
  return res.data;
});

export const deleteContractLogisticsProvider = createAsyncThunk('contractproviders/delete', async (id) => {
  const response = await ContractLogisticsProviderService.deleteContractLogisticsProvider(id);
  return response.data;
});

const ContractLogisticsProviderSlice = createSlice({
  name: 'contractprovider',
  initialState,
  extraReducers: {
    [retrieveContractLogisticsProviders.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteContractLogisticsProvider.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((contractprovider) => contractprovider.id !== id);
    }
  }
});

const { reducer } = ContractLogisticsProviderSlice;
export default reducer;
