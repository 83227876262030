import { protectedResources } from '../config';
import { openSnackbar } from '../store/reducers/snackbar';
import http from '../utils/http-common';
import { getToken } from './utils';
import { ShippingInstructionsCreate, ShippingInstructionsUpdate } from '@myfrey/shipping-instructions-types';

const addShippingInstructions = async (shipmentId: string, data: ShippingInstructionsCreate) => {
  const accessToken = await getToken(protectedResources.api.scopes.read);
  return http.post(`/shipping-instructions/${shipmentId}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const submitShippingInstructions = async (shipmentId: string) => {
  const accessToken = await getToken(protectedResources.api.scopes.read);
  return http.post(
    `/shipping-instructions/submit/${shipmentId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const getShippingInstructions = async (shipmentId: string) => {
  const accessToken = await getToken(protectedResources.api.scopes.read);
  return http.get(`/shipping-instructions/${shipmentId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const generateShippingInstructionsDocument = async (shipmentId: string, shippingInstructionsReference: string) => {
  const accessToken = await getToken(protectedResources.api.scopes.read);
  return http.post(
    `/shipping-instructions/${shipmentId}/${shippingInstructionsReference}/generate`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};

const updateShippingInstructions = async (shipmentId: string, shippingInstructionsReference: string, data: ShippingInstructionsUpdate) => {
  const accessToken = await getToken(protectedResources.api.scopes.read);
  return http.put(`/shipping-instructions/${shipmentId}/${shippingInstructionsReference}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const generateShippingInstructionsDocumentAndTrackStatus = (
  dispatch: any,
  createJob: any,
  shipmentId: string,
  shippingInstructionReference: string
) =>
  async function ({
    onTimeout,
    onOk,
    onError
  }: {
    onTimeout?: () => void;
    onOk?: () => void;
    onError?: () => void;
  } = {}) {
    let jobId: string;
    try {
      const response = await ShippingInstructionsService.generateShippingInstructionsDocument(shipmentId, shippingInstructionReference);
      jobId = response.data.jobId;
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: `Failed to generate shipping instructions PDF`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      onError?.();
      return;
    }
    createJob({
      kind: 'ShippingInstructionsDocumentStatusPollJob',
      shipmentId,
      shippingInstructionReference,
      jobId,
      onTimeout,
      onOk,
      onError
    });
  };

const getGenerateShippingInstructionsDocumentStatus = async (shipmentId: string, shippingInstructionReference: string, jobId: string) => {
  const getGenerateShippingInstructionsDocumentStatusURL = `/shipping-instructions/${shipmentId}/${shippingInstructionReference}/generate-status/${jobId}`;
  const accessToken = await getToken(protectedResources.api.scopes.read);

  return http.get(getGenerateShippingInstructionsDocumentStatusURL, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const ShippingInstructionsService = {
  addShippingInstructions,
  submitShippingInstructions,
  getShippingInstructions,
  generateShippingInstructionsDocument,
  updateShippingInstructions,
  generateShippingInstructionsDocumentAndTrackStatus,
  getGenerateShippingInstructionsDocumentStatus
};

export default ShippingInstructionsService;
