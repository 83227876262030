import { V2 as PurchaseContractV2 } from '@myfrey/purchase-contracts-types';
import { AxiosResponse } from 'axios';
import { protectedResources } from '../../config';
import http from '../../utils/http-common';
import { getToken } from '../utils';

const getPurchaseContract = async (id: string | number): Promise<AxiosResponse<PurchaseContractV2.PurchaseContract>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contracts-refactoring/purchase/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const createPurchaseContract = async (
  contract: PurchaseContractV2.PurchaseContractCreate
): Promise<AxiosResponse<PurchaseContractV2.PurchaseContract>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.post(`/contracts-refactoring/purchase`, contract, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};

const updatePurchaseContract = async (
  id: string,
  contract: PurchaseContractV2.PurchaseContractUpdate
): Promise<AxiosResponse<PurchaseContractV2.PurchaseContract>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.put(`/contracts-refactoring/purchase/${id}`, contract, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const deletePurchaseContract = async (id: string, headers?: Record<string, string | number>): Promise<AxiosResponse<void>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.delete(`/contracts-refactoring/purchase/${id}`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const getGeneratePDFStatus = async (
  id: string,
  jobId: string
): Promise<AxiosResponse<{ jobStatus: 'IN_PROGRESS' | 'FAILED' | 'SUCCESS' }>> => {
  const accessToken = await getToken(protectedResources.contractsnewapi.scopes.read);
  return http.get(`/contracts-refactoring/purchase/${id}/generate-pdf/${jobId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
};
const ContractService = {
  getPurchaseContract,
  createPurchaseContract,
  updatePurchaseContract,
  deletePurchaseContract,
  getGeneratePDFStatus
};

export default ContractService;
