import { pollDocumentGenerationStatus } from '../../pages/Shipments/shipment-details/TabPanels/ShipmentFileManagement/pollDocumentGenerationStatus';
import PurchaseService from '../../services/contracts/purchase.service';
import SaleService from '../../services/contracts/sale.service';
import { CreateNotificationDefinition } from '../NotificationsContext';
import { JobBase } from './JobBase';
import { V2 as PurchaseContractV2 } from '@myfrey/purchase-contracts-types';
import { V2 as SaleContractV2 } from '@myfrey/sale-contracts-types';

type ContractType = (SaleContractV2.SaleContract | PurchaseContractV2.PurchaseContract)['type'];
type ContractDocumentStatusPollJobJSON = {
  id: string;
  kind: 'ContractDocumentStatusPollJob';
  contractId: string;
  contractType: ContractType;
  jobId: string;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProcessNotificationId?: string;
};
type ContractDocumentStatusPollJobStartArgs = {
  addNotification: (notification: CreateNotificationDefinition) => string;
  removeNotification: (notificationId: string) => void;
};
class ContractDocumentStatusPollJob extends JobBase<ContractDocumentStatusPollJobJSON, ContractDocumentStatusPollJobStartArgs> {
  kind = 'ContractDocumentStatusPollJob';
  status = 'pending' as const;

  contractType: ContractType;
  contractId: string;
  jobId: string;
  pollFunction: (contractId: string, jobId: string) => Promise<{ data: { jobStatus: 'IN_PROGRESS' | 'FAILED' | 'SUCCESS' } }>;
  onTimeout?: () => void;
  onOk?: () => void;
  onError?: () => void;
  inProcessNotificationId?: string;

  constructor(id: string, saveJobs: () => void, json: ContractDocumentStatusPollJobJSON) {
    super(id, saveJobs);

    this.jobId = json.jobId;
    this.contractType = json.contractType;
    this.contractId = json.contractId;
    this.onTimeout = json.onTimeout;
    this.onOk = json.onOk;
    this.onError = json.onError;
    this.inProcessNotificationId = json.inProcessNotificationId;
    this.pollFunction = (json.contractType === 'Purchase' ? PurchaseService : SaleService).getGeneratePDFStatus;
  }

  start = ({ addNotification, removeNotification }: ContractDocumentStatusPollJobStartArgs) => {
    this.setStatus('running');
    const { contractId, jobId, onTimeout, onOk, onError } = this;
    pollDocumentGenerationStatus(() => this.pollFunction(contractId, jobId), 0, {
      onTimeout: () => {
        if (this.inProcessNotificationId) return;

        onTimeout?.();
        this.inProcessNotificationId = addNotification({
          message: `${this.contractType} contract PDF generation in progress...`,
          type: 'info',
          refUrl: {
            label: "Go to contract's details",
            url: `/contracts/${this.contractType.toLowerCase()}/${contractId}/details`
          }
        });
        this.saveJobs();
      },
      onOk: () => {
        onOk?.();
        addNotification({
          message: `${this.contractType} contract PDF was generated successfully!`,
          refUrl: {
            label: "Got to contract's details",
            url: `/contracts/${this.contractType.toLowerCase()}/${contractId}/details`
          },
          type: 'success'
        });
        if (this.inProcessNotificationId) removeNotification(this.inProcessNotificationId);
        this.setStatus('finished');
      },
      onError: () => {
        onError?.();
        addNotification({
          message: `${this.contractType} contract PDF failed to generate`,
          refUrl: {
            label: "Got to contract's details",
            url: `/contracts/${this.contractType.toLowerCase()}/${contractId}/details`
          },
          type: 'error'
        });
        if (this.inProcessNotificationId) removeNotification(this.inProcessNotificationId);
        this.setStatus('error');
      }
    });
  };

  cancel = () => {
    this.setStatus('cancelled');
  };

  toJSON = () => {
    return {
      id: this.id,
      kind: this.kind as 'ContractDocumentStatusPollJob',
      contractId: this.contractId,
      contractType: this.contractType,
      jobId: this.jobId,
      inProcessNotificationId: this.inProcessNotificationId
    };
  };
}

export { ContractDocumentStatusPollJob };
export type { ContractDocumentStatusPollJobJSON };
