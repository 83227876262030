import { createContext, useState, useCallback, useContext } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import { Alert, Stack } from '@mui/material';
import { uniqueId } from 'lodash';

import SnackbarToast from '../components/SnackbarToast';

type AlertProps = Parameters<typeof Alert>[0];
type SnackbarProps = Parameters<typeof MuiSnackbar>[0];

type RedundantSnackbarState = {
  id: string;
};

type SnackbarState = {
  action: boolean;
  message: string;
  anchorOrigin: SnackbarProps['anchorOrigin'];
  actionButton: boolean;
  timeout: number | Promise<any>;
} & Pick<SnackbarProps, 'anchorOrigin'> &
  Pick<AlertProps, 'color' | 'variant' | 'severity'>;

const DEFAULT_TIMEOUT = 6000;

const defaultState: SnackbarState = {
  action: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  actionButton: false,
  timeout: DEFAULT_TIMEOUT
};

export const SnackbarContext = createContext({ addSnackbar: (_: Partial<SnackbarState>) => {}, removeSnackbar: (_: string) => {} });

export const SnackbarProvider: React.ComponentType<React.PropsWithChildren> = ({ children }) => {
  const [snackbarStack, setSnackbarStack] = useState<(SnackbarState & RedundantSnackbarState)[]>([]);

  const addSnackbar = useCallback((snackbar: Partial<SnackbarState>) => {
    const id = uniqueId('snackbar_');

    if (snackbar.timeout instanceof Promise) {
      snackbar.timeout.then(() => {
        setSnackbarStack((stack) => stack.filter((s) => s.id !== id));
      });
    }

    const snackbarWithId = {
      ...defaultState,
      ...snackbar,
      id,
      timeout:
        typeof snackbar.timeout === 'number' ? snackbar.timeout : snackbar.timeout instanceof Promise ? 15 * 1000 * 60 : DEFAULT_TIMEOUT
    } as SnackbarState & RedundantSnackbarState;

    setSnackbarStack((stack) => [...stack, snackbarWithId]);
  }, []);

  const removeSnackbar = useCallback((id: string) => {
    setSnackbarStack((stack) => stack.filter((s) => s.id !== id));
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        addSnackbar,
        removeSnackbar
      }}
    >
      <MuiSnackbar
        open={snackbarStack.length > 0}
        autoHideDuration={null}
        transitionDuration={0}
        anchorOrigin={snackbarStack[0]?.anchorOrigin}
      >
        <Stack flexDirection="column" gap={1}>
          {snackbarStack.map((snackbar) => (
            <SnackbarToast key={snackbar.id} toast={snackbar} />
          ))}
        </Stack>
      </MuiSnackbar>

      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { addSnackbar, removeSnackbar } = useContext(SnackbarContext);
  return { addSnackbar, removeSnackbar };
};
