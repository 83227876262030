var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
// ==============================|| RADIO - COLORS ||============================== //
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var lighter = colors.lighter, main = colors.main, dark = colors.dark;
    return {
        '& .dot': {
            backgroundColor: main
        },
        '&:hover': {
            backgroundColor: lighter
        },
        '&.Mui-focusVisible': {
            outline: "2px solid ".concat(dark),
            outlineOffset: -4
        }
    };
}
function getSizeStyle(size) {
    switch (size) {
        case 'small':
            return { size: 16, dotSize: 8, position: 3 };
        case 'large':
            return { size: 24, dotSize: 12, position: 5 };
        case 'medium':
        default:
            return { size: 20, dotSize: 10, position: 4 };
    }
}
// ==============================|| CHECKBOX - STYLE ||============================== //
function radioStyle(size) {
    var sizes = getSizeStyle(size);
    return {
        '& .icon': {
            width: sizes.size,
            height: sizes.size,
            '& .dot': {
                width: sizes.dotSize,
                height: sizes.dotSize,
                top: sizes.position,
                left: sizes.position
            }
        }
    };
}
// ==============================|| OVERRIDES - CHECKBOX ||============================== //
export default function Radio(theme) {
    var palette = theme.palette;
    return {
        MuiRadio: {
            defaultProps: {
                className: 'size-small',
                icon: _jsx(Box, { className: "icon", sx: { width: 16, height: 16, border: '1px solid', borderColor: 'inherit', borderRadius: '50%' } }),
                checkedIcon: (_jsx(Box, { className: "icon", sx: {
                        width: 16,
                        height: 16,
                        border: '1px solid',
                        borderColor: 'inherit',
                        borderRadius: '50%',
                        position: 'relative'
                    }, children: _jsx(Box, { className: "dot", sx: {
                            width: 8,
                            height: 8,
                            backgroundColor: 'inherit',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 3,
                            left: 3
                        } }) }))
            },
            styleOverrides: {
                root: {
                    color: palette.secondary[300],
                    '&.size-small': __assign({}, radioStyle('small')),
                    '&.size-medium': __assign({}, radioStyle('medium')),
                    '&.size-large': __assign({}, radioStyle('large'))
                },
                colorPrimary: getColorStyle({ color: 'primary', theme: theme }),
                colorSecondary: getColorStyle({ color: 'secondary', theme: theme }),
                colorSuccess: getColorStyle({ color: 'success', theme: theme }),
                colorWarning: getColorStyle({ color: 'warning', theme: theme }),
                colorInfo: getColorStyle({ color: 'info', theme: theme }),
                colorError: getColorStyle({ color: 'error', theme: theme })
            }
        }
    };
}
