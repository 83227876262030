type BaseJSONRepresentation = {
  id: string;
  kind: string;
};
type JobStatus = 'pending' | 'running' | 'finished' | 'error' | 'cancelled';
type JobStatusChangeCallback = (jobId: string, oldStatus: JobStatus, newStatus: JobStatus) => void;
type Job<JSONRepresentation extends BaseJSONRepresentation, StartArgs extends object> = {
  id: string;
  kind: string;
  start: (args: StartArgs) => void;
  cancel: () => void;
  toJSON: () => JSONRepresentation;
  onStatusChanged: (callback: JobStatusChangeCallback) => void;
  status: JobStatus;
  callbacks: JobStatusChangeCallback[];
};
abstract class JobBase<JSONRepresentation extends BaseJSONRepresentation, StartArgs extends object>
  implements Job<JSONRepresentation, StartArgs>
{
  callbacks: JobStatusChangeCallback[] = [];
  status: JobStatus = 'pending' as const;
  setStatus = (status: JobStatus) => {
    const oldStatus = this.status;
    this.status = status;
    if (oldStatus === status) return;
    this.callbacks.forEach((callback) => callback(this.id, oldStatus, status));
    this.saveJobs();
  };
  onStatusChanged = (callback: JobStatusChangeCallback) => {
    this.callbacks.push(callback);
  };

  constructor(public id: string, protected saveJobs: () => void) {}

  abstract kind: string;
  abstract start: (args: StartArgs) => void;
  abstract cancel: () => void;
  abstract toJSON: () => JSONRepresentation;
}

export type { BaseJSONRepresentation, JobStatus };
export { JobBase };
