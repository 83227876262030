var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// material-ui
import { alpha } from '@mui/material/styles';
// ==============================|| ALERT - COLORS ||============================== //
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var lighter = colors.lighter, light = colors.light, main = colors.main;
    return {
        borderColor: alpha(light, 0.5),
        backgroundColor: lighter,
        '& .MuiAlert-icon': {
            color: main
        }
    };
}
// ==============================|| OVERRIDES - ALERT ||============================== //
export default function Alert(theme) {
    var primaryDashed = getColorStyle({ color: 'primary', theme: theme });
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    fontSize: '0.875rem'
                },
                icon: {
                    fontSize: '1rem'
                },
                message: {
                    padding: 0,
                    marginTop: 3
                },
                filled: {
                    color: theme.palette.grey[0]
                },
                border: __assign(__assign({ padding: '10px 16px', border: '1px solid' }, primaryDashed), { '&.MuiAlert-borderPrimary': getColorStyle({ color: 'primary', theme: theme }), '&.MuiAlert-borderSecondary': getColorStyle({ color: 'secondary', theme: theme }), '&.MuiAlert-borderError': getColorStyle({ color: 'error', theme: theme }), '&.MuiAlert-borderSuccess': getColorStyle({ color: 'success', theme: theme }), '&.MuiAlert-borderInfo': getColorStyle({ color: 'info', theme: theme }), '&.MuiAlert-borderWarning': getColorStyle({ color: 'warning', theme: theme }) }),
                action: {
                    '& .MuiButton-root': {
                        padding: 2,
                        height: 'auto',
                        fontSize: '0.75rem',
                        marginTop: -2
                    },
                    '& .MuiIconButton-root': {
                        width: 'auto',
                        height: 'auto',
                        padding: 2,
                        marginRight: 6,
                        '& .MuiSvgIcon-root': {
                            fontSize: '1rem'
                        }
                    }
                }
            }
        }
    };
}
