var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function getColorStyle(_a) {
    var variant = _a.variant, color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var lighter = colors.lighter, light = colors.light, dark = colors.dark, main = colors.main, contrastText = colors.contrastText;
    var focusStyle = {
        '&:focus-visible': __assign({ outline: "2px solid ".concat(dark), outlineOffset: 2 }, (variant === 'text' && {
            backgroundColor: theme.palette.background.paper
        }))
    };
    switch (variant) {
        case 'combined':
        case 'contained':
            return __assign({ color: contrastText, backgroundColor: main, '&:hover': {
                    backgroundColor: light
                } }, focusStyle);
        case 'outlined':
            return __assign({ borderColor: main, '&:hover': {
                    backgroundColor: lighter,
                    borderColor: light
                } }, focusStyle);
        case 'text':
        default:
            return __assign({ color: main, '&:hover': {
                    backgroundColor: main,
                    color: lighter
                } }, focusStyle);
    }
}
// ==============================|| OVERRIDES - PAGINATION ITEM ||============================== //
export default function PaginationItem(theme) {
    return {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&:focus-visible': {
                        outline: "2px solid ".concat(theme.palette.secondary.dark),
                        outlineOffset: 2
                    }
                },
                text: {
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        fontSize: '1rem',
                        fontWeight: 500,
                        '&.MuiPaginationItem-textPrimary': getColorStyle({ variant: 'text', color: 'primary', theme: theme }),
                        '&.MuiPaginationItem-textSecondary': getColorStyle({ variant: 'text', color: 'secondary', theme: theme }),
                        '&.MuiPaginationItem-textError': getColorStyle({ variant: 'text', color: 'error', theme: theme }),
                        '&.MuiPaginationItem-textSuccess': getColorStyle({ variant: 'text', color: 'success', theme: theme }),
                        '&.MuiPaginationItem-textInfo': getColorStyle({ variant: 'text', color: 'info', theme: theme }),
                        '&.MuiPaginationItem-textWarning': getColorStyle({ variant: 'text', color: 'warning', theme: theme })
                    }
                },
                contained: {
                    '&.Mui-selected': {
                        '&.MuiPaginationItem-containedPrimary': getColorStyle({ variant: 'contained', color: 'primary', theme: theme }),
                        '&.MuiPaginationItem-containedSecondary': getColorStyle({ variant: 'contained', color: 'secondary', theme: theme }),
                        '&.MuiPaginationItem-containedError': getColorStyle({ variant: 'contained', color: 'error', theme: theme }),
                        '&.MuiPaginationItem-containedSuccess': getColorStyle({ variant: 'contained', color: 'success', theme: theme }),
                        '&.MuiPaginationItem-containedInfo': getColorStyle({ variant: 'contained', color: 'info', theme: theme }),
                        '&.MuiPaginationItem-containedWarning': getColorStyle({ variant: 'contained', color: 'warning', theme: theme })
                    }
                },
                combined: {
                    border: '1px solid',
                    borderColor: theme.palette.divider,
                    '&.MuiPaginationItem-ellipsis': {
                        border: 'none'
                    },
                    '&.Mui-selected': {
                        '&.MuiPaginationItem-combinedPrimary': getColorStyle({ variant: 'combined', color: 'primary', theme: theme }),
                        '&.MuiPaginationItem-combinedSecondary': getColorStyle({ variant: 'combined', color: 'secondary', theme: theme }),
                        '&.MuiPaginationItem-combinedError': getColorStyle({ variant: 'combined', color: 'error', theme: theme }),
                        '&.MuiPaginationItem-combinedSuccess': getColorStyle({ variant: 'combined', color: 'success', theme: theme }),
                        '&.MuiPaginationItem-combinedInfo': getColorStyle({ variant: 'combined', color: 'info', theme: theme }),
                        '&.MuiPaginationItem-combinedWarning': getColorStyle({ variant: 'combined', color: 'warning', theme: theme })
                    }
                },
                outlined: {
                    borderColor: theme.palette.divider,
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        '&.MuiPaginationItem-outlinedPrimary': getColorStyle({ variant: 'outlined', color: 'primary', theme: theme }),
                        '&.MuiPaginationItem-outlinedSecondary': getColorStyle({ variant: 'outlined', color: 'secondary', theme: theme }),
                        '&.MuiPaginationItem-outlinedError': getColorStyle({ variant: 'outlined', color: 'error', theme: theme }),
                        '&.MuiPaginationItem-outlinedSuccess': getColorStyle({ variant: 'outlined', color: 'success', theme: theme }),
                        '&.MuiPaginationItem-outlinedInfo': getColorStyle({ variant: 'outlined', color: 'info', theme: theme }),
                        '&.MuiPaginationItem-outlinedWarning': getColorStyle({ variant: 'outlined', color: 'warning', theme: theme })
                    }
                }
            }
        }
    };
}
