import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LocationService from '../../services/location.service';

const initialState = [];

export const retrieveLocations = createAsyncThunk('locations/retrieve', async () => {
  const res = await LocationService.getAll();
  return res.data;
});

const LocationSlice = createSlice({
  name: 'location',
  initialState,
  extraReducers: {
    [retrieveLocations.fulfilled]: (state, action) => {
      return [...action.payload];
    }
  }
});

const { reducer } = LocationSlice;
export default reducer;
