import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ShipmentPeriodService from '../../services/shipmentperiod.service';

const initialState = [];

export const retrieveShipmentPeriods = createAsyncThunk('shipmentperiods/retrieve', async () => {
  const res = await ShipmentPeriodService.getAll();
  return res.data;
});

export const deleteShipmentPeriod = createAsyncThunk('shipmentperiod/delete', async (id) => {
  const response = await ShipmentPeriodService.deleteShipmentPeriod(id);
  return response.data;
});

const ShipmentPeriodSlice = createSlice({
  name: 'shipmentperiod',
  initialState,
  extraReducers: {
    [retrieveShipmentPeriods.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [deleteShipmentPeriod.fulfilled]: (state, action) => {
      const { id } = action.payload;
      state = state.filter((shipmentperiod) => shipmentperiod.id !== id);
    }
  }
});

const { reducer } = ShipmentPeriodSlice;
export default reducer;
