var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// assets
import { BorderOutlined, CheckSquareFilled, MinusSquareFilled } from '@ant-design/icons';
// ==============================|| RADIO - COLORS ||============================== //
function getColorStyle(_a) {
    var color = _a.color, theme = _a.theme;
    var colors = theme.palette[color];
    var lighter = colors.lighter, main = colors.main, dark = colors.dark;
    return {
        '&:hover': {
            backgroundColor: lighter,
            '& .icon': {
                borderColor: main
            }
        },
        '&.Mui-focusVisible': {
            outline: "2px solid ".concat(dark),
            outlineOffset: -4
        }
    };
}
function getSizeStyle(size) {
    switch (size) {
        case 'small':
            return { fontSize: 1.15 };
        case 'large':
            return { fontSize: 1.6 };
        case 'medium':
        default:
            return { fontSize: 1.35 };
    }
}
// ==============================|| CHECKBOX - STYLE ||============================== //
function checkboxStyle(size) {
    var sizes = getSizeStyle(size);
    return {
        '& .icon': {
            fontSize: "".concat(sizes.fontSize, "rem")
        }
    };
}
// ==============================|| OVERRIDES - CHECKBOX ||============================== //
export default function Checkbox(theme) {
    var palette = theme.palette;
    return {
        MuiCheckbox: {
            defaultProps: {
                className: 'size-small',
                icon: _jsx(BorderOutlined, { className: "icon" }),
                checkedIcon: _jsx(CheckSquareFilled, { className: "icon" }),
                indeterminateIcon: _jsx(MinusSquareFilled, { className: "icon" })
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    color: palette.secondary[300],
                    '&.size-small': __assign({}, checkboxStyle('small')),
                    '&.size-medium': __assign({}, checkboxStyle('medium')),
                    '&.size-large': __assign({}, checkboxStyle('large'))
                },
                colorPrimary: getColorStyle({ color: 'primary', theme: theme }),
                colorSecondary: getColorStyle({ color: 'secondary', theme: theme }),
                colorSuccess: getColorStyle({ color: 'success', theme: theme }),
                colorWarning: getColorStyle({ color: 'warning', theme: theme }),
                colorInfo: getColorStyle({ color: 'info', theme: theme }),
                colorError: getColorStyle({ color: 'error', theme: theme })
            }
        }
    };
}
