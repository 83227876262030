import { AuthProvider } from './AuthProvider';

const activeAccount = {
  environment: 'test',
  homeAccountId: 'test',
  localAccountId: 'test',
  name: 'test',
  tenantId: 'test',
  username: 'test'
};

export const mockAuthProvider: AuthProvider = {
  useAuthProvider: () => ({
    isAdmin: true,
    isVerifying: false,
    isAuthenticating: false,
    isAuthenticated: true,
    getAccountInfo: () => activeAccount,
    getActiveAccount: () => activeAccount,
    loginRedirect: async () => {},
    logoutRedirect: async () => {}
  }),
  rootRouterWrapper: ({ children }) => <> {children} </>,
  rootApplicationWrapper: ({ children }) => <> {children} </>,
  loginWrapper: ({ children }) => <> {children} </>,
  logoutWrapper: ({ children }) => <> {children} </>,
  acquireTokenSilent: async () => ({ accessToken: 'token' }),
  getActiveAccount: () => activeAccount
};
